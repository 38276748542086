import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";

import { AlertService, ProjectService } from "../../_services";
import { Utils } from "../../_helpers";

@Component({ templateUrl: "project.view.html" })
export class ProjectView implements OnInit {
  heading = "View Project";
  subheading = "";
  icon = "fa fa-tasks icon-gradient bg-happy-itmeo";
  projectForm: FormGroup;
  loading = false;
  submitted = false;
  project_id: number;
  preview_image = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private alertService: AlertService
  ) {
    this.project_id = this.route.snapshot.params["id"];
  }

  ngOnInit() {
    this.getProject(this.project_id);
    this.projectForm = this.formBuilder.group({
      project_location: [
        "",
        [Validators.required, Utils.noWhitespaceValidator]
      ],
      status: ["", [Validators.required, Utils.noWhitespaceValidator]]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.projectForm.controls;
  }

  getProject(id) {
    this.projectService.getById(id).subscribe((data: any) => {
      var project = data.result_set;
      this.projectForm.setValue({
        project_location: project.project_location,
        status: project.status
      });
      this.preview_image = project.images;
    });
  }

  getControlLabel(type: string) {
    return this.projectForm.controls[type].value;
  }
}
