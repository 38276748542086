import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";

import { MustMatch } from "../../_helpers/must-match.validator";
import { AlertService, CustomerService } from "../../_services";
import { Utils } from "../../_helpers";

@Component({ templateUrl: "customer.business.html" })
export class CustomerBusiness implements OnInit {
  heading = "Add Customer Business";
  subheading = "";
  icon = "fa fa-users icon-gradient bg-happy-itmeo";
  customerForm: FormGroup;
  loading = false;
  submitted = false;
  customer_id: any;
  name = "";
  customer_email = "";

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private customerService: CustomerService
  ) {
    this.customer_id = this.route.snapshot.params["id"];
  }

  ngOnInit() {
    this.getCustomer(this.customer_id);
    this.customerForm = this.formBuilder.group({
      business_value: ["", [Validators.required]]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.customerForm.controls;
  }

  getCustomer(id) {
    this.customerService.getById(id).subscribe((data: any) => {
      var customer = data.result_set;
      this.name = customer.name;
      this.customer_email = customer.email;
    });
  }
  onSubmit() {
    this.submitted = true;

    if (this.customerForm.invalid) {
      return;
    }

    this.loading = true;

    const formData = new FormData();
    formData.append("customer_id", this.customer_id);
    formData.append("value", this.customerForm.get("business_value").value);
    this.customerService
      .insertbussiness(formData)
      .pipe(first())
      .subscribe(
        (data: any) => {
          if (data.status == "ok") {
            this.alertService.success(data.message, true);
            this.router.navigate(["/customer"]);
          } else {
            this.alertService.error(data.message, true);
            this.loading = false;
          }
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }
}
