import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { PointCompare } from "../../_helpers/must-match.validator";
import { AlertService, PointsService } from "../../_services";
import { Utils } from "../../_helpers";

@Component({ templateUrl: "points.edit.html" })
export class PointsEdit implements OnInit {
  heading = "Edit Coin";
  subheading = "";
  icon = "fa fa-folder-o icon-gradient bg-happy-itmeo";
  pointsForm: FormGroup;
  loading = false;
  submitted = false;
  package_id: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private pointsService: PointsService,
    private alertService: AlertService
  ) {
    this.package_id = this.route.snapshot.params["id"];
  }

  ngOnInit() {
    this.getPoints(this.package_id);
    this.pointsForm = this.formBuilder.group(
      {
        package: ["", [Validators.required, Utils.noWhitespaceValidator]],
        start_value: ["", [Validators.required]],
        end_value: [""],
        value_type: ["P", [Validators.required, Utils.noWhitespaceValidator]],
        package_value: ["", [Validators.required]],
        status: ["A", [Validators.required, Utils.noWhitespaceValidator]]
      },
      {
        validator: PointCompare("start_value", "end_value")
      }
    );
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.pointsForm.controls;
  }

  getPoints(id) {
    this.pointsService.getById(id).subscribe((data: any) => {
      var points = data.result_set;
      this.pointsForm.setValue({
        package: points.package,
        start_value: points.start_value,
        end_value: points.end_value,
        value_type: points.value_type,
        package_value: points.package_value,
        status: points.status
      });
    });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.pointsForm.invalid) {
      return;
    }

    this.loading = true;

    const formData = new FormData();
    formData.append("package_id", this.package_id);
    formData.append("package", this.pointsForm.get("package").value);
    formData.append("start_value", this.pointsForm.get("start_value").value);
    formData.append("end_value", this.pointsForm.get("end_value").value);
    formData.append("value_type", this.pointsForm.get("value_type").value);
    formData.append(
      "package_value",
      this.pointsForm.get("package_value").value
    );
    formData.append("status", this.pointsForm.get("status").value);

    this.pointsService
      .update(formData)
      .pipe(first())
      .subscribe(
        (data: any) => {
          if (data.status == "ok") {
            this.alertService.success(data.message, true);
            this.router.navigate(["/points"]);
          } else {
            this.alertService.error(data.message, true);
          }
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }
}
