import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-page-title",
  templateUrl: "./page-title.component.html"
})
export class PageTitleComponent {
  @Input() heading;
  @Input() subheading;
  @Input() icon;
  @Input() link;
  @Input() coinadd;
  @Input() coinlist;
  @Input() redeemdeal;
  @Input() business;
  @Input() exportbutton;
  @Output() exported = new EventEmitter<any>();

  export() {
    this.exported.emit();
  }
}
