import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import {AngularEditorConfig} from '@kolkov/angular-editor';

import { AlertService, PageService } from '../../_services';
import { Utils } from '../../_helpers';

@Component({templateUrl: 'page.add.html'})
export class PageAdd implements OnInit {
    heading = 'Add Page';
    subheading = '';
    icon = 'fa fa-clone icon-gradient bg-happy-itmeo';
    pageForm: FormGroup;
    loading = false;
    submitted = false;
    page_image = null;
    preview_page_image = null;

    wyswigconfig: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: '15rem',
      minHeight: '5rem',
      placeholder: 'Enter text here...',
      translate: 'no',
      customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText'
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ]
    };

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private pageService: PageService,
        private alertService: AlertService
    ) { }

    ngOnInit() {
        this.pageForm = this.formBuilder.group({
            page_title: ['', [Validators.required, Utils.noWhitespaceValidator]],
            page_slug: ['', [Validators.required, Utils.noWhitespaceValidator]],
            page_meta_title: ['', [Validators.required, Utils.noWhitespaceValidator]],
            page_meta_description: ['', [Validators.required, Utils.noWhitespaceValidator]],
            page_meta_keyword: ['', [Validators.required, Utils.noWhitespaceValidator]],
            page_image: [''],
            page_description: ['', Validators.required],
            page_status: ['A', [Validators.required, Utils.noWhitespaceValidator]]
        });
    }

    onKey(event:any) {
      let slug = event.target.value.replace(/[&\/\\#,+()$~%.'":*?<>{}^@ ]/g,'-');
      this.pageForm.get('page_slug').setValue(slug.toLowerCase());
    }

    // convenience getter for easy access to form fields
    get f() { return this.pageForm.controls; }

    IconChange(files: FileList) {
        this.page_image = files[0];
        this.pageForm.get('page_image').setValue(this.page_image.name);
        var reader = new FileReader();
        reader.readAsDataURL(this.page_image); 
        reader.onload = (_event) => { 
          this.preview_page_image = reader.result; 
        }
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.pageForm.invalid) {
            return;
        }

        this.loading = true;

        const formData = new FormData();
        formData.append('title', this.pageForm.get('page_title').value);
        formData.append('slug', this.pageForm.get('page_slug').value);
        formData.append('meta_title', this.pageForm.get('page_meta_title').value);
        formData.append('meta_description', this.pageForm.get('page_meta_description').value);
        formData.append('meta_keyword', this.pageForm.get('page_meta_keyword').value);
        formData.append('description', this.pageForm.get('page_description').value);
        formData.append('status', this.pageForm.get('page_status').value);
        
        if(this.page_image!=null) {
          formData.append('image', this.page_image, this.page_image.name);
        }
        
        this.pageService.insert(formData).pipe(first()).subscribe((data: any) => {
              if(data.status=='ok') {
                this.alertService.success(data.message, true);
                this.router.navigate(['/page']);
              } else {
                this.alertService.error(data.message, true);
                this.loading = false;
              }
            },
            error => {
                this.alertService.error(error);
                this.loading = false;
            }
        );
    }
}