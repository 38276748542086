import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: "root" })
export class EmailtemplateService {
  constructor(public http: HttpClient) {}

  public getAll(filter: any = []) {
    return this.http.post<any>(`emailtemplate/listtemplate`, filter);
  }

  getById(id: number) {
    return this.http.get(`emailtemplate/view/` + id);
  }

  insert(block: any) {
    return this.http.post(`emailtemplate/add`, block);
  }

  update(block: any) {
    return this.http.post(`emailtemplate/update`, block);
  }

  delete(id: number) {
    return this.http.get(`emailtemplate/delete/` + id);
  }
}
