import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, BlockService } from '../../_services';
import { Utils } from '../../_helpers';

@Component({templateUrl: 'block.view.html'})
export class BlockView implements OnInit {
    heading = 'View Page';
    subheading = '';
    icon = 'fa fa-cubes icon-gradient bg-happy-itmeo';
    blockForm: FormGroup;
    loading = false;
    submitted = false;
    block_id: number;
    preview_image = null;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private blockService: BlockService,
        private alertService: AlertService
    ) { 
        this.block_id = this.route.snapshot.params['id'];
    }

    ngOnInit() {
        this.getBlock(this.block_id);
        this.blockForm = this.formBuilder.group({
          title: ['', [Validators.required, Utils.noWhitespaceValidator]],
          slug: ['', [Validators.required, Utils.noWhitespaceValidator]],
          description: ['', Validators.required],
          status: ['', [Validators.required, Utils.noWhitespaceValidator]]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.blockForm.controls; }

    getBlock(id) {
        this.blockService.getById(id).subscribe((data: any) => {
            var block = data.result_set;
            this.blockForm.setValue({
              title: block.title,
              slug: block.slug,
              description: block.description,
              status: block.status
            });
            this.preview_image = block.image;
        });
    }

    getControlLabel(type: string){
     return this.blockForm.controls[type].value;
    }
}