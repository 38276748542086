import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ShowroomService {
    constructor(public http: HttpClient) { }

    public getAll(filter: any = []) {
        return this.http.post<any>(`showroom/listShowroom`, filter);
    }

    getById(id: number) {
        return this.http.get(`showroom/view/` + id);
    }

    insert(showroom: any) {
        return this.http.post(`showroom/add`, showroom);
    }

    update(showroom: any) {
        return this.http.post(`showroom/update`, showroom);
    }

    delete(id: number) {
        return this.http.get(`showroom/delete/` + id);
    }
}
