import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// import { Setting } from '../_models';

@Injectable({ providedIn: 'root' })
export class SettingService {
    constructor(private http: HttpClient) { }

    getSetting() {
        return this.http.get<any>(`setting/list`);
    }

    save(setting: any) {
        return this.http.post(`setting/save`, setting);
    }
}