import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";

import { AlertService, CategoryService } from "../../_services";
import { Utils } from "../../_helpers";

@Component({ templateUrl: "category.add.html" })
export class CategoryAdd implements OnInit {
  heading = "Add Deal Category";
  subheading = "";
  icon = "fa fa-folder-o icon-gradient bg-happy-itmeo";
  categoryForm: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private categoryService: CategoryService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.categoryForm = this.formBuilder.group({
      category_name: ["", [Validators.required, Utils.noWhitespaceValidator]],
      category_status: ["A", [Validators.required, Utils.noWhitespaceValidator]]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.categoryForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.categoryForm.invalid) {
      return;
    }

    this.loading = true;

    const formData = new FormData();
    formData.append(
      "category_name",
      this.categoryForm.get("category_name").value
    );
    formData.append("status", this.categoryForm.get("category_status").value);

    this.categoryService
      .insert(formData)
      .pipe(first())
      .subscribe(
        (data: any) => {
          if (data.status == "ok") {
            this.alertService.success(data.message, true);
            this.router.navigate(["/category"]);
          } else {
            this.alertService.error(data.message, true);
            this.loading = false;
          }
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }
}
