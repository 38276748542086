import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgReduxModule } from "@angular-redux/store";
import { NgRedux, DevToolsExtension } from "@angular-redux/store";
import { rootReducer, ArchitectUIState } from "./ThemeOptions/store";
import { ConfigActions } from "./ThemeOptions/store/config.actions";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";

import { AppComponent } from "./app.component";
import { routing } from "./app.routing";

import { AlertComponent } from "./_components";
import { JwtInterceptor, ErrorInterceptor } from "./_helpers";
import { HomeComponent } from "./Pages/Home/home.component";
import { SettingsComponent } from "./Pages/Home/settings.component";
import { LoginComponent } from "./Pages/Login/login.component";

// CATEGORY
import { CategoryList } from "./Pages/Category/category.list";
import { CategoryAdd } from "./Pages/Category/category.add";
import { CategoryEdit } from "./Pages/Category/category.edit";
import { CategoryView } from "./Pages/Category/category.view";

// MERCHANT
import { MerchantList } from "./Pages/Merchant/merchant.list";
import { MerchantAdd } from "./Pages/Merchant/merchant.add";
import { MerchantEdit } from "./Pages/Merchant/merchant.edit";
import { MerchantView } from "./Pages/Merchant/merchant.view";

// DEALS
import { DealList } from "./Pages/Deal/deal.list";
import { DealAdd } from "./Pages/Deal/deal.add";
import { DealEdit } from "./Pages/Deal/deal.edit";
import { DealView } from "./Pages/Deal/deal.view";
import { DealRedeem } from "./Pages/Deal/deal.redeem";

// CUSTOMER
import { CustomerList } from "./Pages/Customer/customer.list";
import { CustomerAdd } from "./Pages/Customer/customer.add";
import { CustomerEdit } from "./Pages/Customer/customer.edit";
import { CustomerView } from "./Pages/Customer/customer.view";
import { CustomerBusiness } from "./Pages/Customer/customer.business";
import { CustomerBusinessList } from "./Pages/Customer/customer.businesslist";
import { CustomerListCoin } from "./Pages/Customer/customer.listcoin";
import { CustomerAddCoin } from "./Pages/Customer/customer.addcoin";

// PAGE
import { PageList } from "./Pages/Page/page.list";
import { PageAdd } from "./Pages/Page/page.add";
import { PageEdit } from "./Pages/Page/page.edit";
import { PageView } from "./Pages/Page/page.view";

// STATIC BLOCK
import { BlockList } from "./Pages/Staticblock/block.list";
import { BlockAdd } from "./Pages/Staticblock/block.add";
import { BlockEdit } from "./Pages/Staticblock/block.edit";
import { BlockView } from "./Pages/Staticblock/block.view";

// Announcement BLOCK
import { AnnouncementList } from "./Pages/Announcement/announcement.list";
import { AnnouncementAdd } from "./Pages/Announcement/announcement.add";
import { AnnouncementEdit } from "./Pages/Announcement/announcement.edit";
import { AnnouncementView } from "./Pages/Announcement/announcement.view";

// Project BLOCK
import { ProjectList } from "./Pages/Project/project.list";
import { ProjectAdd } from "./Pages/Project/project.add";
import { ProjectEdit } from "./Pages/Project/project.edit";
import { ProjectView } from "./Pages/Project/project.view";

// Best Showroom BLOCK
import { BestshowroomList } from "./Pages/Bestshowroom/bestshowroom.list";

// Points BLOCK
import { PointsList } from "./Pages/Points/points.list";
import { PointsAdd } from "./Pages/Points/points.add";
import { PointsEdit } from "./Pages/Points/points.edit";
import { PointsView } from "./Pages/Points/points.view";

// Email Template BLOCK
import { EmailtemplateList } from "./Pages/Emailtemplate/emailtemplate.list";
import { EmailtemplateAdd } from "./Pages/Emailtemplate/emailtemplate.add";
import { EmailtemplateEdit } from "./Pages/Emailtemplate/emailtemplate.edit";
import { EmailtemplateView } from "./Pages/Emailtemplate/emailtemplate.view";

// EMPLOYEE
import { EmployeeList } from "./Pages/Employees/employee.list";
import { EmployeeAdd } from "./Pages/Employees/employee.add";
import { EmployeeEdit } from "./Pages/Employees/employee.edit";
import { EmployeeView } from "./Pages/Employees/employee.view";

// Showroom
import { ShowroomList } from "./Pages/Showroom/showroom.list";
import { ShowroomAdd } from "./Pages/Showroom/showroom.add";
import { ShowroomEdit } from "./Pages/Showroom/showroom.edit";
import { ShowroomView } from "./Pages/Showroom/showroom.view";

// BOOTSTRAP COMPONENTS

import { AngularFontAwesomeModule } from "angular-font-awesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { LaddaModule } from "angular2-ladda";
import { NgxLoadingModule } from "ngx-loading";
import { RoundProgressModule } from "angular-svg-round-progressbar";
import { SweetAlert2Module } from "@toverux/ngx-sweetalert2";
import { ToastrModule } from "ngx-toastr";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { CountUpModule } from "countup.js-angular2";
import { AgmCoreModule } from "@agm/core";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgBootstrapFormValidationModule } from "ng-bootstrap-form-validation";
import { AngularStickyThingsModule } from "@w11k/angular-sticky-things";
import { NouisliderModule } from "ng2-nouislider";
import { NgSelectModule } from "@ng-select/ng-select";
import { SelectDropDownModule } from "ngx-select-dropdown";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { TextMaskModule } from "angular2-text-mask";
import { ClipboardModule } from "ngx-clipboard";
import { TextareaAutosizeModule } from "ngx-textarea-autosize";
import { ColorPickerModule } from "ngx-color-picker";
import { DropzoneModule } from "ngx-dropzone-wrapper";
import { DROPZONE_CONFIG } from "ngx-dropzone-wrapper";
import { DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { ChartsModule } from "ng2-charts";

// ANGULAR MATERIAL COMPONENTS

import { MatCheckboxModule, MatRippleModule } from "@angular/material";
import { MatButtonModule } from "@angular/material";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatListModule } from "@angular/material/list";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatCardModule } from "@angular/material/card";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTabsModule } from "@angular/material/tabs";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTreeModule } from "@angular/material/tree";

// LAYOUT

import { BaseLayoutComponent } from "./Layout/base-layout/base-layout.component";
import { AppsLayoutComponent } from "./Layout/apps-layout/apps-layout.component";
import { PagesLayoutComponent } from "./Layout/pages-layout/pages-layout.component";
import { ThemeOptions } from "./theme-options";
import { OptionsDrawerComponent } from "./ThemeOptions/options-drawer/options-drawer.component";
import { PageTitleComponent } from "./Layout/Components/page-title/page-title.component";

// HEADER

import { HeaderComponent } from "./Layout/Components/header/header.component";
import { DotsComponent } from "./Layout/Components/header/elements/dots/dots.component";
import { SearchBoxComponent } from "./Layout/Components/header/elements/search-box/search-box.component";
import { MegamenuComponent } from "./Layout/Components/header/elements/mega-menu/mega-menu.component";
import { MegapopoverComponent } from "./Layout/Components/header/elements/mega-menu/elements/megapopover/megapopover.component";
import { UserBoxComponent } from "./Layout/Components/header/elements/user-box/user-box.component";
import { DrawerComponent } from "./Layout/Components/header/elements/drawer/drawer.component";

// SIDEBAR

import { SidebarComponent } from "./Layout/Components/sidebar/sidebar.component";
import { LogoComponent } from "./Layout/Components/sidebar/elements/logo/logo.component";

// FOOTER

import { FooterComponent } from "./Layout/Components/footer/footer.component";
import { FooterDotsComponent } from "./Layout/Components/footer/elements/footer-dots/footer-dots.component";
import { FooterMenuComponent } from "./Layout/Components/footer/elements/footer-menu/footer-menu.component";

// Apex Charts

import { NgApexchartsModule } from "ng-apexcharts";
// import {SeriesPipe} from './DemoPages/Charts/apexcharts/series.pipe';

// Gauges Charts

import { GaugeModule } from "angular-gauge";
import { TrendModule } from "ngx-trend";
/*
// Angular Material

import {AutoCompleteComponent} from './DemoPages/Material/FormControls/auto-complete/auto-complete.component';
import {CheckboxComponent} from './DemoPages/Material/FormControls/checkbox/checkbox.component';
import {RadioComponent} from './DemoPages/Material/FormControls/radio/radio.component';
import {DatepickerComponent2} from './DemoPages/Material/FormControls/mat-datepicker/mat-datepicker.component';
import {FormFieldComponent} from './DemoPages/Material/FormControls/form-field/form-field.component';
import {InputComponent} from './DemoPages/Material/FormControls/input/input.component';
import {SelectComponent} from './DemoPages/Material/FormControls/select/select.component';
import {SliderComponent} from './DemoPages/Material/FormControls/slider/slider.component';
import {SliderToggleComponent} from './DemoPages/Material/FormControls/slider-toggle/slider-toggle.component';
import {MenuMatComponent} from './DemoPages/Material/Layout/menu/menu.component';
import {CardMatComponent} from './DemoPages/Material/Layout/card/card.component';
import {DividerComponent} from './DemoPages/Material/Layout/divider/divider.component';
import {ExpansionPanelComponent} from './DemoPages/Material/Layout/expansion-panel/expansion-panel.component';
import {GridListComponent} from './DemoPages/Material/Layout/grid-list/grid-list.component';
import {ListMatComponent} from './DemoPages/Material/Layout/list/list.component';
import {StepperComponent} from './DemoPages/Material/Layout/stepper/stepper.component';
import {TreeComponent} from './DemoPages/Material/Layout/tree/tree.component';
import {ButtonToggleComponent} from './DemoPages/Material/ButtonsIndicators/button-toggle/button-toggle.component';
import {ChipsComponent} from './DemoPages/Material/ButtonsIndicators/chips/chips.component';
import {ProgressSpinnerComponent} from './DemoPages/Material/ButtonsIndicators/progress-spinner/progress-spinner.component';
import {RipplesComponent} from './DemoPages/Material/ButtonsIndicators/ripples/ripples.component';
import {SnackbarComponent} from './DemoPages/Material/Layout/snackbar/snackbar.component';
import {TooltipComponent} from './DemoPages/Material/Layout/tooltip/tooltip.component';
import {PaginatorComponent} from './DemoPages/Material/Datatable/paginator/paginator.component';
import {TableComponent} from './DemoPages/Material/Datatable/table/table.component';
import {MatTabsComponent} from './DemoPages/Material/Layout/mat-tabs/mat-tabs.component';
import {MatButtonsComponent} from './DemoPages/Material/ButtonsIndicators/mat-buttons/mat-buttons.component';
import {MatProgressBarComponent} from './DemoPages/Material/ButtonsIndicators/mat-progress-bar/mat-progress-bar.component';
*/
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: "https://httpbin.org/post",
  maxFilesize: 50,
  acceptedFiles: "image/*"
};

@NgModule({
  imports: [
    BrowserModule,
    routing,
    BrowserAnimationsModule,
    NgReduxModule,
    CommonModule,
    LoadingBarRouterModule,

    // Angular Bootstrap Components

    PerfectScrollbarModule,
    NgbModule,
    AngularFontAwesomeModule,
    LaddaModule,
    FormsModule,
    ReactiveFormsModule,
    NgBootstrapFormValidationModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    RoundProgressModule,
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      customClass: "modal-content",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn"
    }),
    ToastrModule.forRoot(),
    SlickCarouselModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    CountUpModule,
    AgmCoreModule.forRoot({
      // please get your own API key here:
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
      apiKey: ""
    }),
    ImageCropperModule,
    AngularStickyThingsModule,
    NouisliderModule,
    NgSelectModule,
    SelectDropDownModule,
    NgMultiSelectDropDownModule.forRoot(),
    JwBootstrapSwitchNg2Module,
    AngularEditorModule,
    HttpClientModule,
    TextMaskModule,
    ClipboardModule,
    TextareaAutosizeModule,
    ColorPickerModule,
    DropzoneModule,

    // Charts

    ChartsModule,
    NgApexchartsModule,
    GaugeModule.forRoot(),
    TrendModule,

    // Angular Material Components

    MatCheckboxModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatTreeModule,
    MatRippleModule
  ],
  declarations: [
    AppComponent,
    AlertComponent,
    HomeComponent,
    SettingsComponent,
    LoginComponent,
    // CATEGORY
    CategoryList,
    CategoryAdd,
    CategoryEdit,
    CategoryView,
    // SHOWROOM
    ShowroomList,
    ShowroomAdd,
    ShowroomEdit,
    ShowroomView,
    // MERCHANT
    MerchantList,
    MerchantAdd,
    MerchantEdit,
    MerchantView,
    // DEALS
    DealList,
    DealAdd,
    DealEdit,
    DealView,
    DealRedeem,
    // CUSTOMER
    CustomerList,
    CustomerAdd,
    CustomerEdit,
    CustomerView,
    CustomerBusiness,
    CustomerBusinessList,
    CustomerListCoin,
    CustomerAddCoin,
    // PAGE
    PageList,
    PageAdd,
    PageEdit,
    PageView,
    // STATIC BLOCK
    BlockList,
    BlockAdd,
    BlockEdit,
    BlockView,
    // Announcement
    AnnouncementList,
    AnnouncementAdd,
    AnnouncementEdit,
    AnnouncementView,
    // Project
    ProjectList,
    ProjectAdd,
    ProjectEdit,
    ProjectView,
    // Best Showroom
    BestshowroomList,
    // Points
    PointsList,
    PointsAdd,
    PointsEdit,
    PointsView,
    // Email Template
    EmailtemplateList,
    EmailtemplateAdd,
    EmailtemplateEdit,
    EmailtemplateView,
    // EMPLOYEE
    EmployeeList,
    EmployeeAdd,
    EmployeeEdit,
    EmployeeView,
    // LAYOUT
    AppComponent,
    BaseLayoutComponent,
    AppsLayoutComponent,
    PagesLayoutComponent,
    OptionsDrawerComponent,
    PageTitleComponent,
    // HEADER
    HeaderComponent,
    DotsComponent,
    SearchBoxComponent,
    MegamenuComponent,
    MegapopoverComponent,
    UserBoxComponent,
    DrawerComponent,
    // SIDEBAR
    SidebarComponent,
    LogoComponent,
    // FOOTER
    FooterComponent,
    FooterDotsComponent,
    FooterMenuComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
      // DEFAULT_DROPZONE_CONFIG,
    },
    ConfigActions,
    ThemeOptions
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private ngRedux: NgRedux<ArchitectUIState>,
    private devTool: DevToolsExtension
  ) {
    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : f => f]
    );
  }
}
