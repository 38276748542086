import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { PointCompare } from "../../_helpers/must-match.validator";
import { AlertService, PointsService } from "../../_services";
import { Utils } from "../../_helpers";

@Component({ templateUrl: "points.add.html" })
export class PointsAdd implements OnInit {
  heading = "Add Coin";
  subheading = "";
  icon = "fa fa-folder-o icon-gradient bg-happy-itmeo";
  pointsForm: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private pointsService: PointsService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.pointsForm = this.formBuilder.group(
      {
        package: ["", [Validators.required, Utils.noWhitespaceValidator]],
        start_value: ["", [Validators.required]],
        end_value: [""],
        value_type: ["P", [Validators.required, Utils.noWhitespaceValidator]],
        package_value: ["", [Validators.required]],
        status: ["A", [Validators.required, Utils.noWhitespaceValidator]]
      },
      {
        validator: PointCompare("start_value", "end_value")
      }
    );
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.pointsForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.pointsForm.invalid) {
      return;
    }

    this.loading = true;

    const formData = new FormData();
    formData.append("package", this.pointsForm.get("package").value);
    formData.append("start_value", this.pointsForm.get("start_value").value);
    formData.append("end_value", this.pointsForm.get("end_value").value);
    formData.append("value_type", this.pointsForm.get("value_type").value);
    formData.append(
      "package_value",
      this.pointsForm.get("package_value").value
    );
    formData.append("status", this.pointsForm.get("status").value);

    this.pointsService
      .insert(formData)
      .pipe(first())
      .subscribe(
        (data: any) => {
          if (data.status == "ok") {
            this.alertService.success(data.message, true);
            this.router.navigate(["/points"]);
          } else {
            this.alertService.error(data.message, true);
            this.loading = false;
          }
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }
}
