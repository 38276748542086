import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

// import { Announcement } from '../_models';

@Injectable({ providedIn: "root" })
export class AnnouncementService {
  constructor(public http: HttpClient) {}

  public getAll(filter: any = []) {
    return this.http.post<any>(`announcement/listannouncement`, filter);
  }

  getById(id: number) {
    return this.http.get(`announcement/view/` + id);
  }

  insert(merchant: any) {
    return this.http.post(`announcement/add`, merchant);
  }

  update(merchant: any) {
    return this.http.post(`announcement/update`, merchant);
  }

  delete(id: number) {
    return this.http.get(`announcement/delete/` + id);
  }
}
