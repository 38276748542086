import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// import { Category } from '../_models';

@Injectable({ providedIn: 'root' })
export class CategoryService {
    constructor(public http: HttpClient) { }

    public getAll(filter: any = []) {
        return this.http.post<any>(`category/listCategory`, filter);
    }

    getById(id: number) {
        return this.http.get(`category/view/` + id);
    }

    insert(category: any) {
        return this.http.post(`category/add`, category);
    }

    update(category: any) {
        return this.http.post(`category/update`, category);
    }

    delete(id: number) {
        return this.http.get(`category/delete/` + id);
    }
}
