import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgReduxModule } from "@angular-redux/store";
import { NgRedux, DevToolsExtension } from "@angular-redux/store";
import { rootReducer, ArchitectUIState } from "./ThemeOptions/store";
import { ConfigActions } from "./ThemeOptions/store/config.actions";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";

import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";

import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./_guards";

import { BaseLayoutComponent } from "./Layout/base-layout/base-layout.component";
import { PagesLayoutComponent } from "./Layout/pages-layout/pages-layout.component";

import { HomeComponent } from "./Pages/Home/home.component";
import { SettingsComponent } from "./Pages/Home/settings.component";
import { LoginComponent } from "./Pages/Login/login.component";

// CATEGORY
import { CategoryList } from "./Pages/Category/category.list";
import { CategoryAdd } from "./Pages/Category/category.add";
import { CategoryEdit } from "./Pages/Category/category.edit";
import { CategoryView } from "./Pages/Category/category.view";

// MERCHANT
import { MerchantList } from "./Pages/Merchant/merchant.list";
import { MerchantAdd } from "./Pages/Merchant/merchant.add";
import { MerchantEdit } from "./Pages/Merchant/merchant.edit";
import { MerchantView } from "./Pages/Merchant/merchant.view";

// DEALS
import { DealList } from "./Pages/Deal/deal.list";
import { DealAdd } from "./Pages/Deal/deal.add";
import { DealEdit } from "./Pages/Deal/deal.edit";
import { DealView } from "./Pages/Deal/deal.view";
import { DealRedeem } from "./Pages/Deal/deal.redeem";

// CUSTOMER
import { CustomerList } from "./Pages/Customer/customer.list";
import { CustomerAdd } from "./Pages/Customer/customer.add";
import { CustomerEdit } from "./Pages/Customer/customer.edit";
import { CustomerView } from "./Pages/Customer/customer.view";
import { CustomerBusiness } from "./Pages/Customer/customer.business";
import { CustomerBusinessList } from "./Pages/Customer/customer.businesslist";

import { CustomerListCoin } from "./Pages/Customer/customer.listcoin";
import { CustomerAddCoin } from "./Pages/Customer/customer.addcoin";

// PAGE
import { PageList } from "./Pages/Page/page.list";
import { PageAdd } from "./Pages/Page/page.add";
import { PageEdit } from "./Pages/Page/page.edit";
import { PageView } from "./Pages/Page/page.view";

// STATIC BLOCK
import { BlockList } from "./Pages/Staticblock/block.list";
import { BlockAdd } from "./Pages/Staticblock/block.add";
import { BlockEdit } from "./Pages/Staticblock/block.edit";
import { BlockView } from "./Pages/Staticblock/block.view";

// Announcement BLOCK
import { AnnouncementList } from "./Pages/Announcement/announcement.list";
import { AnnouncementAdd } from "./Pages/Announcement/announcement.add";
import { AnnouncementEdit } from "./Pages/Announcement/announcement.edit";
import { AnnouncementView } from "./Pages/Announcement/announcement.view";

// Project BLOCK
import { ProjectList } from "./Pages/Project/project.list";
import { ProjectAdd } from "./Pages/Project/project.add";
import { ProjectEdit } from "./Pages/Project/project.edit";
import { ProjectView } from "./Pages/Project/project.view";

// Best Showroom BLOCK
import { BestshowroomList } from "./Pages/Bestshowroom/bestshowroom.list";

// Points BLOCK
import { PointsList } from "./Pages/Points/points.list";
import { PointsAdd } from "./Pages/Points/points.add";
import { PointsEdit } from "./Pages/Points/points.edit";
import { PointsView } from "./Pages/Points/points.view";

// Email Template BLOCK
import { EmailtemplateList } from "./Pages/Emailtemplate/emailtemplate.list";
import { EmailtemplateAdd } from "./Pages/Emailtemplate/emailtemplate.add";
import { EmailtemplateEdit } from "./Pages/Emailtemplate/emailtemplate.edit";
import { EmailtemplateView } from "./Pages/Emailtemplate/emailtemplate.view";

// EMPLOYEE
import { EmployeeList } from "./Pages/Employees/employee.list";
import { EmployeeAdd } from "./Pages/Employees/employee.add";
import { EmployeeEdit } from "./Pages/Employees/employee.edit";
import { EmployeeView } from "./Pages/Employees/employee.view";

// Showroom
import { ShowroomList } from "./Pages/Showroom/showroom.list";
import { ShowroomAdd } from "./Pages/Showroom/showroom.add";
import { ShowroomEdit } from "./Pages/Showroom/showroom.edit";
import { ShowroomView } from "./Pages/Showroom/showroom.view";

const appRoutes: Routes = [
  {
    path: "",
    component: BaseLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: "dashboard",
        component: HomeComponent,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "settings",
        component: SettingsComponent,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "category",
        component: CategoryList,
        data: { extraParameter: "dashboardsMenu", role: "category" }
      },
      {
        path: "category/add",
        component: CategoryAdd,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "category/edit/:id",
        component: CategoryEdit,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "category/view/:id",
        component: CategoryView,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "showroom",
        component: ShowroomList,
        data: { extraParameter: "dashboardsMenu", role: "showroom" }
      },
      {
        path: "showroom/add",
        component: ShowroomAdd,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "showroom/edit/:id",
        component: ShowroomEdit,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "showroom/view/:id",
        component: ShowroomView,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "merchant",
        component: MerchantList,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "merchant/add",
        component: MerchantAdd,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "merchant/edit/:id",
        component: MerchantEdit,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "merchant/view/:id",
        component: MerchantView,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "deal",
        component: DealList,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "deal/add",
        component: DealAdd,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "deal/edit/:id",
        component: DealEdit,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "deal/view/:id",
        component: DealView,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "redeem",
        component: DealRedeem,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "customer",
        component: CustomerList,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "customer/add",
        component: CustomerAdd,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "customer/edit/:id",
        component: CustomerEdit,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "customer/view/:id",
        component: CustomerView,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "customer/business/:id",
        component: CustomerBusiness,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "customer/businesslist",
        component: CustomerBusinessList,
        data: { extraParameter: "dashboardsMenu" }
      },

      {
        path: "customer/coinlist/:id",
        component: CustomerListCoin,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "customer/addcoin",
        component: CustomerAddCoin,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "page",
        component: PageList,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "page/add",
        component: PageAdd,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "page/edit/:id",
        component: PageEdit,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "page/view/:id",
        component: PageView,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "static_block",
        component: BlockList,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "static_block/add",
        component: BlockAdd,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "static_block/edit/:id",
        component: BlockEdit,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "static_block/view/:id",
        component: BlockView,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "announcement",
        component: AnnouncementList,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "announcement/add",
        component: AnnouncementAdd,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "announcement/edit/:id",
        component: AnnouncementEdit,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "announcement/view/:id",
        component: AnnouncementView,
        data: { extraParameter: "dashboardsMenu" }
      },

      {
        path: "project",
        component: ProjectList,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "project/add",
        component: ProjectAdd,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "project/edit/:id",
        component: ProjectEdit,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "project/view/:id",
        component: ProjectView,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "best-showroom",
        component: BestshowroomList,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "points",
        component: PointsList,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "points/add",
        component: PointsAdd,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "points/edit/:id",
        component: PointsEdit,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "points/view/:id",
        component: PointsView,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "emailtemplate",
        component: EmailtemplateList,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "emailtemplate/add",
        component: EmailtemplateAdd,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "emailtemplate/edit/:id",
        component: EmailtemplateEdit,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "emailtemplate/view/:id",
        component: EmailtemplateView,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "employees",
        component: EmployeeList,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "employee/add",
        component: EmployeeAdd,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "employee/edit/:id",
        component: EmployeeEdit,
        data: { extraParameter: "dashboardsMenu" }
      },
      {
        path: "employee/view/:id",
        component: EmployeeView,
        data: { extraParameter: "dashboardsMenu" }
      }
    ]
  },
  {
    path: "",
    component: PagesLayoutComponent,
    children: [
      { path: "login", component: LoginComponent, data: { extraParameter: "" } }
    ]
  }
];

export const routing = RouterModule.forRoot(appRoutes);
