import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, RoutesRecognized, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';

import { AuthenticationService, AlertService } from '../_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private activatedRoute: ActivatedRoute,
        private alertService: AlertService
    ) {}


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // const currentUser = this.authenticationService.currentUserValue;
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        
        if (currentUser) {
            /*if(String(currentUser.permissions)=="all") {
                return true;
            } else {
                this.router.events.subscribe(e => {
                    if (e instanceof NavigationEnd) {
                        const currentURL = e.url;
                        if(currentURL!='/') {
                            let ar = currentURL.split('/');
                            ar.length = 3;
                            let currentPath = ar.join('/');
                            let permissions = currentUser.permissions;
                            let pos = permissions.search(currentPath);                        
                            if(pos<0) {
                                this.alertService.error("Permission Denied", true);
                                this.router.navigate(['/']);
                                return false;
                            }  
                        }                      
                    }
                });
            }*/
                     
            // check if route is restricted by role
            if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
                // role not authorised so redirect to home page
                this.router.navigate(['/']);
                return false;
            }

            // authorised so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }

}