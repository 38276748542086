import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";

import { MustMatch } from "../../_helpers/must-match.validator";
import {
  AlertService,
  MerchantService,
  CustomerService
} from "../../_services";
import { Utils } from "../../_helpers";

@Component({ templateUrl: "customer.edit.html" })
export class CustomerEdit implements OnInit {
  heading = "Edit Customer";
  subheading = "";
  icon = "fa fa-users icon-gradient bg-happy-itmeo";
  customerForm: FormGroup;
  loading = false;
  submitted = false;
  customer_profile = null;
  merchants = [];
  preview_customer_profile = null;
  customer_id: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private merchantServices: MerchantService,
    private customerService: CustomerService
  ) {
    this.customer_id = this.route.snapshot.params["id"];
  }

  ngOnInit() {
    this.getCustomer(this.customer_id);
    this.customerForm = this.formBuilder.group(
      {
        name: ["", [Validators.required, Utils.noWhitespaceValidator]],
        customer_email: [
          "",
          [
            Validators.required,
            Utils.noWhitespaceValidator,
            Validators.pattern(
              "[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
            )
          ]
        ],
        mobile_number: [
          "",
          [Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.maxLength(8)]
        ],
        customer_password: [""],
        customer_confirm_password: [""],
        customer_profile: [""],
        customer_status: [
          "",
          [Validators.required, Utils.noWhitespaceValidator]
        ]
      },
      {
        validator: MustMatch("customer_password", "customer_confirm_password")
      }
    );
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.customerForm.controls;
  }

  getCustomer(id) {
    this.customerService.getById(id).subscribe((data: any) => {
      var customer = data.result_set;
      this.customerForm.setValue({
        name: customer.name,
        mobile_number: customer.mobile_number,
        customer_email: customer.email,
        customer_password: "",
        customer_confirm_password: "",
        customer_profile: customer.photo,
        customer_status: customer.status
      });
      this.preview_customer_profile = customer.photo;
    });
  }

  IconChange(files: FileList) {
    this.customer_profile = files[0];
    this.customerForm
      .get("customer_profile")
      .setValue(this.customer_profile.name);
    var reader = new FileReader();
    reader.readAsDataURL(this.customer_profile);
    reader.onload = _event => {
      this.preview_customer_profile = reader.result;
    };
  }

  onSubmit() {
    this.submitted = true;

    if (this.customerForm.invalid) {
      return;
    }

    this.loading = true;

    const formData = new FormData();
    formData.append("customer_id", this.customer_id);
    formData.append("name", this.customerForm.get("name").value);
    formData.append(
      "mobile_number",
      this.customerForm.get("mobile_number").value
    );
    formData.append("email", this.customerForm.get("customer_email").value);
    formData.append(
      "password",
      this.customerForm.get("customer_password").value
    );
    formData.append("status", this.customerForm.get("customer_status").value);

    if (this.customer_profile != null) {
      formData.append(
        "photo",
        this.customer_profile,
        this.customer_profile.name
      );
    }

    this.customerService
      .update(formData)
      .pipe(first())
      .subscribe(
        (data: any) => {
          if (data.status == "ok") {
            this.alertService.success(data.message, true);
            this.router.navigate(["/customer"]);
          } else {
            this.alertService.error(data.message, true);
            this.loading = false;
          }
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }
}
