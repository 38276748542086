import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import {MatTableDataSource, MatSort, MatPaginator} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';

import { AlertService, BlockService } from '../../_services';

@Component({ templateUrl: 'block.list.html' })
export class BlockList implements OnInit, OnDestroy {
    
    heading = 'Static Block';
    subheading = '';
    icon = 'fa fa-cubes icon-gradient bg-happy-itmeo';
    link = '/static_block/add';
    pages: [];
    dataSource: MatTableDataSource<any[]>;
    search_key = "";
    pageSizeOptions = null;

    displayedColumns = [ 's_no','title', 'slug','status', 'actions'];
    

    @ViewChild(MatPaginator) paginator: MatPaginator;  
    @ViewChild(MatSort) sort: MatSort;
    
    setPageSizeOptions(setPageSizeOptionsInput: string) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }

    constructor( 
        private blockServices: BlockService,
        private alertService: AlertService ) { }

    ngOnInit() {
        this.loadAllPages();
    }

    ngOnDestroy() {
    }

    clearFilters(){
       this.dataSource.filter = '';
       this.search_key = '';
    }

    private loadAllPages() {
        this.blockServices.getAll().pipe(first()).subscribe((pages: any) => {
        	if(pages.status=='ok') {
                this.dataSource = new MatTableDataSource(pages.result_set);
                this.dataSource.paginator = this.paginator;  
                this.dataSource.sort = this.sort; 
        	}
        });
    }

    deleteBlock(id: number) {
        this.alertService.delete().then(data=>{
            if(data) {
                this.blockServices.delete(id).pipe(first()).subscribe((data: any) => {
                    if(data.status=='ok') {
                      this.alertService.success(data.message, true);
                      this.loadAllPages()
                    } else {
                      this.alertService.error(data.message, true);
                    }
                });
            } 
        });
    }

    applyFilter(filterValue: string) {  
      this.dataSource.filter = filterValue.trim().toLowerCase();  
    
      if (this.dataSource.paginator) {  
        this.dataSource.paginator.firstPage();  
      }  
    }

}
