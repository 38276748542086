import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";

import { AlertService, EmployeeService } from "../../_services";
import { Utils } from "../../_helpers";

@Component({ templateUrl: "employee.edit.html" })
export class EmployeeEdit implements OnInit {
  heading = "Edit Employee";
  subheading = "";
  icon = "fa fa-users icon-gradient bg-happy-itmeo";
  employeeForm: FormGroup;
  loading = false;
  submitted = false;
  id: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private employeeService: EmployeeService,
    private alertService: AlertService
  ) {
    this.id = this.route.snapshot.params["id"];
  }

  ngOnInit() {
    this.getEmployee(this.id);
    this.employeeForm = this.formBuilder.group({
      employee_name: ["", [Validators.required, Utils.noWhitespaceValidator]],
      employee_id: ["", [Validators.required, Utils.noWhitespaceValidator]]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.employeeForm.controls;
  }

  getEmployee(id) {
    this.employeeService.getById(id).subscribe((data: any) => {
      var employee = data.result_set;
      this.employeeForm.setValue({
        employee_name: employee.employee_name,
        employee_id: employee.employee_id
      });
    });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.employeeForm.invalid) {
      return;
    }

    this.loading = true;

    const formData = new FormData();
    formData.append("id", this.id);
    formData.append("employee_name", this.employeeForm.get("employee_name").value);
    formData.append("employee_id", this.employeeForm.get("employee_id").value);

    this.employeeService
      .update(formData)
      .pipe(first())
      .subscribe(
        (data: any) => {
          if (data.status == "ok") {
            this.alertService.success(data.message, true);
            this.router.navigate(["/employees"]);
          } else {
            this.alertService.error(data.message, true);
          }
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }
}
