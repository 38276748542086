import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { MatTableDataSource, MatSort, MatPaginator } from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";

// import { Customer } from '../../_models';
import { AlertService, CustomerService } from "../../_services";

@Component({ templateUrl: "customer.list.html" })
export class CustomerList implements OnInit, OnDestroy {
  heading = "Customers";
  subheading = "";
  icon = "fa fa-users icon-gradient bg-happy-itmeo";
  link = "/customer/add";
  coinadd = "/customer/addcoin";
  coinlist = "/customer/coinlist/all";
  business = "/customer/businesslist";
  exportbutton = "true";
  customers: [];
  dataSource: MatTableDataSource<any[]>;
  pageSizeOptions = null;
  search_key = "";
  data: any = [];

  displayedColumns = ["s_no", "name", "email", "mobile_number", "actions"];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(",").map(str => +str);
  }

  constructor(
    private customerService: CustomerService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.loadAllCustomers();
  }

  ngOnDestroy() {}

  clearFilters() {
    this.dataSource.filter = "";
    this.search_key = "";
  }

  private loadAllCustomers() {
    this.customerService
      .getAll()
      .pipe(first())
      .subscribe((customers: any) => {
        if (customers.status == "ok") {
          this.dataSource = new MatTableDataSource(customers.result_set);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.data = customers.result_set;
        }
      });
  }

  deleteCustomer(id: number) {
    this.alertService.delete().then(data => {
      if (data) {
        this.customerService
          .delete(id)
          .pipe(first())
          .subscribe((data: any) => {
            if (data.status == "ok") {
              this.alertService.success(data.message, true);
              this.loadAllCustomers();
            } else {
              this.alertService.error(data.message, true);
            }
          });
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onExported() {
    let fileName = 'CustomersList.csv';
    let columnNames = ["Name", "Email", "Mobile Number", "Coin", "Referred By (Name | Email)", "Employee Reference (ID | Name)"];
    let header = columnNames.join(',');

    let csv = header;
    csv += '\r\n';

    this.data.map(c => {
      csv += [(c['name'])?'"' + c['name'].toString() + '"':'', (c['email'])?'"' + c['email'].toString() + '"':'', (c['mobile_number'])?'"' + c['mobile_number'].toString() + '"':'', (c['coin'])?'"' + c['coin'].toString() + '"':'', (c['r_name'])?'"' + c['r_name'].toString() + ' | ' + c['r_email'] + '"':'', (c['employee_id'])?'"' + c['employee_id'].toString() + ' | ' + c['employee_name'] + '"':'' ].join(',');
      csv += '\r\n';
    })

    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    var link = document.createElement("a");
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
