import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { MatTableDataSource, MatSort, MatPaginator } from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";

// import { Customer } from '../../_models';
import { AlertService, CustomerService } from "../../_services";

@Component({ templateUrl: "customer.businesslist.html" })
export class CustomerBusinessList implements OnInit, OnDestroy {
  heading = "Business Deal";
  subheading = "";
  icon = "fa fa-users icon-gradient bg-happy-itmeo";

  customers: [];
  dataSource: MatTableDataSource<any[]>;
  pageSizeOptions = null;
  search_key = "";

  displayedColumns = [
    "s_no",
    "c_name",
    "c_email",
    "points",
    "coin",
    "r_name",
    "r_email",
    "referral_coin"
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(",").map(str => +str);
  }

  constructor(
    private customerService: CustomerService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.loadAllCustomers();
  }

  ngOnDestroy() {}

  clearFilters() {
    this.dataSource.filter = "";
    this.search_key = "";
  }

  private loadAllCustomers() {
    this.customerService
      .getbussinessAll()
      .pipe(first())
      .subscribe((customers: any) => {
        if (customers.status == "ok") {
          this.dataSource = new MatTableDataSource(customers.result_set);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      });
  }

  deleteCustomer(id: number) {
    this.alertService.delete().then(data => {
      if (data) {
        this.customerService
          .delete(id)
          .pipe(first())
          .subscribe((data: any) => {
            if (data.status == "ok") {
              this.alertService.success(data.message, true);
              this.loadAllCustomers();
            } else {
              this.alertService.error(data.message, true);
            }
          });
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
