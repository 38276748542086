import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, PageService } from '../../_services';
import { Utils } from '../../_helpers';

@Component({templateUrl: 'page.view.html'})
export class PageView implements OnInit {
    heading = 'View Page';
    subheading = '';
    icon = 'fa fa-clone icon-gradient bg-happy-itmeo';
    pageForm: FormGroup;
    loading = false;
    submitted = false;
    page_id: number;
    preview_page_image = null;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private pageService: PageService,
        private alertService: AlertService
    ) { 
        this.page_id = this.route.snapshot.params['id'];
    }

    ngOnInit() {
        this.getPage(this.page_id);
        this.pageForm = this.formBuilder.group({
            page_title: ['', [Validators.required, Utils.noWhitespaceValidator]],
            page_slug: ['', [Validators.required, Utils.noWhitespaceValidator]],
            page_meta_title: ['', [Validators.required, Utils.noWhitespaceValidator]],
            page_meta_description: ['', [Validators.required, Utils.noWhitespaceValidator]],
            page_meta_keyword: ['', [Validators.required, Utils.noWhitespaceValidator]],
            page_description: ['', Validators.required],
            page_status: ['', [Validators.required, Utils.noWhitespaceValidator]]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.pageForm.controls; }

    getPage(id) {
        this.pageService.getById(id).subscribe((data: any) => {
            var page = data.result_set;
            this.pageForm.setValue({
              page_title: page.title,
              page_slug: page.slug,
              page_meta_title: page.meta_title,
              page_meta_description: page.meta_description,
              page_meta_keyword: page.meta_keyword,
              page_description: page.description,
              page_status: page.status
            });
            this.preview_page_image = page.image;
        });
    }

    getControlLabel(type: string){
     return this.pageForm.controls[type].value;
    }
}