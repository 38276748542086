import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { AngularEditorConfig } from "@kolkov/angular-editor";

import { AlertService, AnnouncementService } from "../../_services";
import { Utils } from "../../_helpers";

@Component({ templateUrl: "announcement.edit.html" })
export class AnnouncementEdit implements OnInit {
  heading = "Edit Announcement";
  subheading = "";
  icon = "fa fa-bullhorn icon-gradient bg-happy-itmeo";
  announcementForm: FormGroup;
  loading = false;
  submitted = false;
  announcement_id: any;
  image = null;
  preview_image = null;
  ExpminDate = new Date();
  ExpmaxDate = null;

  wyswigconfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: "Enter text here...",
    translate: "no",
    customClasses: [
      {
        name: "quote",
        class: "quote"
      },
      {
        name: "redText",
        class: "redText"
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1"
      }
    ]
  };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private announcementService: AnnouncementService,
    private alertService: AlertService
  ) {
    this.announcement_id = this.route.snapshot.params["id"];
  }

  ngOnInit() {
    this.getAnnouncement(this.announcement_id);
    this.announcementForm = this.formBuilder.group({
      title: ["", [Validators.required, Utils.noWhitespaceValidator]],
      image: ["", [Validators.required, Utils.noWhitespaceValidator]],
      description: ["", [Validators.required, Utils.noWhitespaceValidator]],
      start_date: ["", Validators.required],
      end_date: ["", Validators.required],
      status: ["", [Validators.required, Utils.noWhitespaceValidator]]
    });
    this.ExpStrtDateChange();
    this.ExpEndDateChange();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.announcementForm.controls;
  }

  IconChange(files: FileList) {
    this.image = files[0];
    this.announcementForm.get("image").setValue(this.image.name);
    var reader = new FileReader();
    reader.readAsDataURL(this.image);
    reader.onload = _event => {
      this.preview_image = reader.result;
    };
  }
  ExpStrtDateChange() {
    this.announcementForm
      .get("start_date")
      .valueChanges.subscribe((Date: any) => {
        if (Date) {
          this.ExpminDate = Date;
        }
      });
  }

  ExpEndDateChange() {
    this.announcementForm
      .get("end_date")
      .valueChanges.subscribe((Date: any) => {
        if (Date) {
          this.ExpmaxDate = Date;
        }
      });
  }

  getAnnouncement(id) {
    this.announcementService.getById(id).subscribe((data: any) => {
      var announcement = data.result_set;
      this.announcementForm.setValue({
        title: announcement.title,
        image: announcement.image,
        description: announcement.description,
        start_date: new Date(announcement.start_date),
        end_date: new Date(announcement.end_date),
        status: announcement.status
      });
      this.preview_image = announcement.image;
    });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.announcementForm.invalid) {
      return;
    }

    this.loading = true;

    const formData = new FormData();
    formData.append("announcement_id", this.announcement_id);
    formData.append("title", this.announcementForm.get("title").value);
    formData.append(
      "description",
      this.announcementForm.get("description").value
    );
    formData.append(
      "start_date",
      new Date(
        `${this.announcementForm.get("start_date").value} UTC`
      ).toISOString()
    );
    formData.append(
      "end_date",
      new Date(
        `${this.announcementForm.get("end_date").value} UTC`
      ).toISOString()
    );
    formData.append("status", this.announcementForm.get("status").value);

    if (this.image != null) {
      formData.append("image", this.image, this.image.name);
    }

    this.announcementService
      .update(formData)
      .pipe(first())
      .subscribe(
        (data: any) => {
          if (data.status == "ok") {
            this.alertService.success(data.message, true);
            this.router.navigate(["/announcement"]);
          } else {
            this.alertService.error(data.message, true);
            this.loading = false;
          }
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }
}
