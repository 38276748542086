import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: "root" })
export class DealService {
  constructor(public http: HttpClient) {}

  public getAll(filter: any = []) {
    return this.http.post<any>(`deals/listdeals`, filter);
  }
  getAllRedeem(filter: any = []) {
    return this.http.post<any>(`redeem/listredeem`, filter);
  }

  getById(id: number) {
    return this.http.get(`deals/view/` + id);
  }

  insert(deal: any) {
    return this.http.post(`deals/add`, deal);
  }

  update(deal: any) {
    return this.http.post(`deals/update`, deal);
  }

  delete(id: number) {
    return this.http.get(`deals/delete/` + id);
  }
}
