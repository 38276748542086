import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

// import { Setting } from '../../_models';
import { SettingService, AlertService } from "../../_services";
import { Utils } from "../../_helpers";

@Component({ templateUrl: "settings.component.html" })
export class SettingsComponent implements OnInit, OnDestroy {
  heading = "Settings";
  subheading = "";
  icon = "fa fa-cog icon-gradient bg-happy-itmeo";
  settingForm: FormGroup;
  loading = false;
  submitted = false;
  site_logo = null;
  preview_site_logo = null;
  favicon = null;
  preview_favicon = null;
  email_logo = null;
  preview_email_logo = null;
  powered_by = null;
  preview_powered_by = null;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private settingService: SettingService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.getSettings();
    this.settingForm = this.formBuilder.group({
      settings_site_title: [
        "",
        [Validators.required, Utils.noWhitespaceValidator]
      ],
      meta_title: ["", [Validators.required, Utils.noWhitespaceValidator]],
      meta_Keywords: ["", [Validators.required, Utils.noWhitespaceValidator]],
      meta_description: [""],
      settings_to_email: [""],
      settings_from_email: [""],
      settings_email_footer: [""],
      settings_mail_from_smtp: [""],
      settings_smtp_host: [""],
      settings_smtp_user: [""],
      settings_smtp_pass: [""],
      settings_smtp_port: [""],
      settings_mailpath: [""],
      settings_email_logo: [""],
      footer_copywriting: [""],
      settings_site_logo: [""],
      settings_favicon: [""],
      settings_powered_by: [""],
      settings_referral_point: [""],
      after_referral_point: [""],
      settings_sign_up_point: [""],
      best_showroom_point: [""]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.settingForm.controls;
  }

  ngOnDestroy() {}

  private getSettings() {
    this.settingService.getSetting().subscribe((data: any) => {
      var settings = data.result_set;
      this.settingForm.setValue({
        settings_site_title: settings.settings_site_title,
        meta_title: settings.meta_title,
        meta_Keywords: settings.meta_Keywords,
        meta_description: settings.meta_description,
        settings_to_email: settings.settings_to_email,
        settings_from_email: settings.settings_from_email,
        settings_email_footer: settings.settings_email_footer,
        settings_mail_from_smtp: settings.settings_mail_from_smtp,
        settings_smtp_host: settings.settings_smtp_host,
        settings_smtp_user: settings.settings_smtp_user,
        settings_smtp_pass: settings.settings_smtp_pass,
        settings_smtp_port: settings.settings_smtp_port,
        settings_mailpath: settings.settings_mailpath,
        settings_email_logo: settings.settings_email_logo,
        footer_copywriting: settings.footer_copywriting,
        settings_site_logo: settings.settings_site_logo,
        settings_favicon: settings.settings_favicon,
        settings_powered_by: settings.powdered_by,
        settings_referral_point: settings.referral_point,
        settings_sign_up_point: settings.sign_up_point,
        after_referral_point: settings.after_referral_point,
        best_showroom_point: settings.best_showroom_point
      });
      this.preview_site_logo = settings.settings_site_logo;
      this.preview_favicon = settings.settings_favicon;
      this.preview_email_logo = settings.settings_email_logo;
      this.preview_powered_by = settings.powdered_by;
    });
  }

  LogoChange(files: FileList) {
    this.site_logo = files[0];
    this.settingForm.get("settings_site_logo").setValue(this.site_logo.name);
    var reader = new FileReader();
    reader.readAsDataURL(this.site_logo);
    reader.onload = _event => {
      this.preview_site_logo = reader.result;
    };
  }

  IconChange(files: FileList) {
    this.favicon = files[0];
    this.settingForm.get("settings_favicon").setValue(this.favicon.name);
    var reader = new FileReader();
    reader.readAsDataURL(this.favicon);
    reader.onload = _event => {
      this.preview_favicon = reader.result;
    };
  }

  EmailLogoChange(files: FileList) {
    this.email_logo = files[0];
    this.settingForm.get("settings_email_logo").setValue(this.email_logo.name);
    var reader = new FileReader();
    reader.readAsDataURL(this.email_logo);
    reader.onload = _event => {
      this.preview_email_logo = reader.result;
    };
  }

  PoweredByChange(files: FileList) {
    this.powered_by = files[0];
    this.settingForm.get("settings_powered_by").setValue(this.powered_by.name);
    var reader = new FileReader();
    reader.readAsDataURL(this.powered_by);
    reader.onload = _event => {
      this.preview_powered_by = reader.result;
    };
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.settingForm.invalid) {
      return;
    }

    this.loading = true;

    const formData = new FormData();
    formData.append(
      "settings_site_title",
      this.settingForm.get("settings_site_title").value
    );
    formData.append("meta_title", this.settingForm.get("meta_title").value);
    formData.append(
      "meta_Keywords",
      this.settingForm.get("meta_Keywords").value
    );
    formData.append(
      "meta_description",
      this.settingForm.get("meta_description").value
    );
    formData.append(
      "settings_to_email",
      this.settingForm.get("settings_to_email").value
    );
    formData.append(
      "settings_from_email",
      this.settingForm.get("settings_from_email").value
    );
    formData.append(
      "settings_email_footer",
      this.settingForm.get("settings_email_footer").value
    );
    formData.append(
      "settings_mail_from_smtp",
      this.settingForm.get("settings_mail_from_smtp").value
    );
    formData.append(
      "settings_smtp_host",
      this.settingForm.get("settings_smtp_host").value
    );
    formData.append(
      "settings_smtp_user",
      this.settingForm.get("settings_smtp_user").value
    );
    formData.append(
      "settings_smtp_pass",
      this.settingForm.get("settings_smtp_pass").value
    );
    formData.append(
      "settings_smtp_port",
      this.settingForm.get("settings_smtp_port").value
    );
    formData.append(
      "settings_mailpath",
      this.settingForm.get("settings_mailpath").value
    );
    formData.append(
      "footer_copywriting",
      this.settingForm.get("footer_copywriting").value
    );

    formData.append(
      "referral_point",
      this.settingForm.get("settings_referral_point").value
    );
    formData.append(
      "after_referral_point",
      this.settingForm.get("after_referral_point").value
    );
    formData.append(
      "sign_up_point",
      this.settingForm.get("settings_sign_up_point").value
    );
    formData.append(
      "best_showroom_point",
      this.settingForm.get("best_showroom_point").value
    );

    if (this.site_logo != null) {
      formData.append(
        "settings_site_logo",
        this.site_logo,
        this.site_logo.name
      );
    }

    if (this.favicon != null) {
      formData.append("settings_favicon", this.favicon, this.favicon.name);
    }

    if (this.email_logo != null) {
      formData.append(
        "settings_email_logo",
        this.email_logo,
        this.email_logo.name
      );
    }

    if (this.powered_by != null) {
      formData.append("powdered_by", this.powered_by, this.powered_by.name);
    }

    this.settingService
      .save(formData)
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.loading = false;
          if (data.status == "ok") {
            this.settingForm.reset();
            this.getSettings();
            this.alertService.success(data.message, true);
            this.router.navigate(["/settings"]);
          } else {
            this.alertService.error(data.message, true);
          }
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }
}
