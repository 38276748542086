import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";

import { AlertService, CategoryService } from "../../_services";
import { Utils } from "../../_helpers";

@Component({ templateUrl: "category.view.html" })
export class CategoryView implements OnInit {
  heading = "View Deal Category";
  subheading = "";
  icon = "fa fa-folder-o icon-gradient bg-happy-itmeo";
  categoryForm: FormGroup;
  loading = false;
  submitted = false;
  category_id: number;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    private alertService: AlertService
  ) {
    this.category_id = this.route.snapshot.params["id"];
  }

  ngOnInit() {
    this.getCategory(this.category_id);
    this.categoryForm = this.formBuilder.group({
      category_name: ["", [Validators.required, Utils.noWhitespaceValidator]],
      category_status: ["", [Validators.required, Utils.noWhitespaceValidator]]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.categoryForm.controls;
  }

  getCategory(id) {
    this.categoryService.getById(id).subscribe((data: any) => {
      var category = data.result_set;
      this.categoryForm.setValue({
        category_name: category.category_name,
        category_status: category.status
      });
    });
  }

  getControlLabel(type: string) {
    return this.categoryForm.controls[type].value;
  }
}
