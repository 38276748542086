import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";

import { MustMatch } from "../../_helpers/must-match.validator";
import {
  AlertService,
  MerchantService,
  CustomerService
} from "../../_services";
import { Utils } from "../../_helpers";

@Component({ templateUrl: "customer.add.html" })
export class CustomerAdd implements OnInit {
  heading = "Add Customer";
  subheading = "";
  icon = "fa fa-users icon-gradient bg-happy-itmeo";
  customerForm: FormGroup;
  loading = false;
  submitted = false;
  customer_profile = null;
  preview_customer_profile = null;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private merchantServices: MerchantService,
    private customerService: CustomerService
  ) {}

  ngOnInit() {
    this.customerForm = this.formBuilder.group(
      {
        name: ["", [Validators.required, Utils.noWhitespaceValidator]],
        mobile_number: [
          "",
          [Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.maxLength(8)]
        ],
        customer_email: [
          "",
          [
            Validators.required,
            Utils.noWhitespaceValidator,
            Validators.pattern(
              "[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
            )
          ]
        ],
        join_points: [""]
        /* customer_password: [
          "",
          [Validators.required, Utils.noWhitespaceValidator]
        ],
        customer_confirm_password: [
          "",
          [Validators.required, Utils.noWhitespaceValidator]
        ],
        customer_profile: [""],
        customer_status: [
          "A",
          [Validators.required, Utils.noWhitespaceValidator]
        ] */
      } /* ,
      {
        validator: MustMatch("customer_password", "customer_confirm_password")
      } */
    );
  }

  IconChange(files: FileList) {
    this.customer_profile = files[0];
    this.customerForm
      .get("customer_profile")
      .setValue(this.customer_profile.name);
    var reader = new FileReader();
    reader.readAsDataURL(this.customer_profile);
    reader.onload = _event => {
      this.preview_customer_profile = reader.result;
    };
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.customerForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.customerForm.invalid) {
      return;
    }

    this.loading = true;

    const formData = new FormData();
    formData.append("name", this.customerForm.get("name").value);
    formData.append("email", this.customerForm.get("customer_email").value);
    formData.append(
      "mobile_number",
      this.customerForm.get("mobile_number").value
    );
    formData.append("join_points", this.customerForm.get("join_points").value);

    /* formData.append('password', this.customerForm.get('customer_password').value);
        formData.append('status', this.customerForm.get('customer_status').value);

        if(this.customer_profile!=null) {
          formData.append('photo', this.customer_profile, this.customer_profile.name);
        } */

    this.customerService
      .insert(formData)
      .pipe(first())
      .subscribe(
        (data: any) => {
          if (data.status == "ok") {
            this.alertService.success(data.message, true);
            this.router.navigate(["/customer"]);
          } else {
            this.alertService.error(data.message, true);
            this.loading = false;
          }
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }
}
