import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

// import { Customer } from '../_models';

@Injectable({ providedIn: "root" })
export class CustomerService {
  constructor(public http: HttpClient) {}

  public getAll(filter: any = []) {
    return this.http.post<any>(`customers/listcustomers`, filter);
  }
  getcoinAll(filter: any = []) {
    return this.http.post<any>(`customers/coin_historylist`, filter);
  }

  getById(id: number) {
    return this.http.get<any>(`customers/view/` + id);
  }

  insert(customer: any) {
    return this.http.post(`customers/add`, customer);
  }

  update(customer: any) {
    return this.http.post(`customers/update`, customer);
  }

  delete(id: number) {
    return this.http.get(`customers/delete/` + id);
  }

  insertcoin(customer: any) {
    return this.http.post(`customers/add_debit_coin`, customer);
  }

  insertbussiness(customer: any) {
    return this.http.post(`customers/bussiness_deal`, customer);
  }

  getbussinessAll(filter: any = []) {
    return this.http.post<any>(`customers/listbussinessdeal`, filter);
  }

  /*getInfoById(id: number) {
        return this.http.get<any>(`customers/view/` + id);
    }*/
}
