import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import {AngularEditorConfig} from '@kolkov/angular-editor';

import { AlertService, BlockService } from '../../_services';
import { Utils } from '../../_helpers';

@Component({templateUrl: 'block.add.html'})
export class BlockAdd implements OnInit {
    heading = 'Add Static Block';
    subheading = '';
    icon = 'fa fa-cubes icon-gradient bg-happy-itmeo';
    blockForm: FormGroup;
    loading = false;
    submitted = false;
    image = null;
    preview_image = null;

    wyswigconfig: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: '15rem',
      minHeight: '5rem',
      placeholder: 'Enter text here...',
      translate: 'no',
      customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText'
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ]
    };

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private blockService: BlockService,
        private alertService: AlertService
    ) { }

    ngOnInit() {
        this.blockForm = this.formBuilder.group({
          title: ['', [Validators.required, Utils.noWhitespaceValidator]],
          slug: ['', [Validators.required, Utils.noWhitespaceValidator]],
          image: [''],
          description: ['', Validators.required],
          status: ['A', [Validators.required, Utils.noWhitespaceValidator]]
        });
    }

    onKey(event:any) {
      let slug = event.target.value.replace(/[&\/\\#,+()$~%.'":*?<>{}^@ ]/g,'-');
      this.blockForm.get('slug').setValue(slug.toLowerCase());
    }

    // convenience getter for easy access to form fields
    get f() { return this.blockForm.controls; }

    IconChange(files: FileList) {
        this.image = files[0];
        this.blockForm.get('image').setValue(this.image.name);
        var reader = new FileReader();
        reader.readAsDataURL(this.image); 
        reader.onload = (_event) => { 
          this.preview_image = reader.result; 
        }
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.blockForm.invalid) {
            return;
        }

        this.loading = true;

        const formData = new FormData();
        formData.append('title', this.blockForm.get('title').value);
        formData.append('slug', this.blockForm.get('slug').value);
        formData.append('description', this.blockForm.get('description').value);
        formData.append('status', this.blockForm.get('status').value);
        
        if(this.image!=null) {
          formData.append('image', this.image, this.image.name);
        }

        this.blockService.insert(formData).pipe(first()).subscribe((data: any) => {
              if(data.status=='ok') {
                this.alertService.success(data.message, true);
                this.router.navigate(['/static_block']);
              } else {
                this.alertService.error(data.message, true);
                this.loading = false;
              }
            },
            error => {
                this.alertService.error(error);
                this.loading = false;
            }
        );
    }
}