import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";

import { AlertService, PointsService } from "../../_services";
import { Utils } from "../../_helpers";

@Component({ templateUrl: "points.view.html" })
export class PointsView implements OnInit {
  heading = "View Coin";
  subheading = "";
  icon = "fa fa-folder-o icon-gradient bg-happy-itmeo";
  pointsForm: FormGroup;
  loading = false;
  submitted = false;
  package_id: number;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private pointsService: PointsService,
    private alertService: AlertService
  ) {
    this.package_id = this.route.snapshot.params["id"];
  }

  ngOnInit() {
    this.getPoints(this.package_id);
    this.pointsForm = this.formBuilder.group({
      package: ["", [Validators.required, Utils.noWhitespaceValidator]],
      start_value: ["", [Validators.required, Utils.noWhitespaceValidator]],
      end_value: [""],
      value_type: ["", [Validators.required, Utils.noWhitespaceValidator]],
      package_value: ["", [Validators.required, Utils.noWhitespaceValidator]],
      status: ["", [Validators.required, Utils.noWhitespaceValidator]]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.pointsForm.controls;
  }

  getPoints(id) {
    this.pointsService.getById(id).subscribe((data: any) => {
      var points = data.result_set;
      this.pointsForm.setValue({
        package: points.package,
        start_value: points.start_value,
        end_value: points.end_value,
        value_type: points.value_type,
        package_value: points.package_value,
        status: points.status
      });
    });
  }

  getControlLabel(type: string) {
    return this.pointsForm.controls[type].value;
  }
}
