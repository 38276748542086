import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";

import { AlertService, ShowroomService } from "../../_services";
import { Utils } from "../../_helpers";

@Component({ templateUrl: "showroom.view.html" })
export class ShowroomView implements OnInit {
  heading = "View Showroom";
  subheading = "";
  icon = "fa fa-folder-o icon-gradient bg-happy-itmeo";
  showroomForm: FormGroup;
  loading = false;
  submitted = false;
  showroom_id: number;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private showroomService: ShowroomService,
    private alertService: AlertService
  ) {
    this.showroom_id = this.route.snapshot.params["id"];
  }

  ngOnInit() {
    this.getShowroom(this.showroom_id);
    this.showroomForm = this.formBuilder.group({
      showroom_name: ["", [Validators.required, Utils.noWhitespaceValidator]],
      showroom_status: ["", [Validators.required, Utils.noWhitespaceValidator]]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.showroomForm.controls;
  }

  getShowroom(id) {
    this.showroomService.getById(id).subscribe((data: any) => {
      var showroom = data.result_set;
      this.showroomForm.setValue({
        showroom_name: showroom.showroom_name,
        showroom_status: showroom.status
      });
    });
  }

  getControlLabel(type: string) {
    return this.showroomForm.controls[type].value;
  }
}
