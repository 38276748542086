import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { AngularEditorConfig } from "@kolkov/angular-editor";

import { AlertService, MerchantService } from "../../_services";
import { Utils } from "../../_helpers";

@Component({ templateUrl: "merchant.add.html" })
export class MerchantAdd implements OnInit {
  heading = "Add Merchant";
  subheading = "";
  icon = "fa fa-briefcase icon-gradient bg-happy-itmeo";
  merchantForm: FormGroup;
  loading = false;
  submitted = false;
  merchant_image = null;
  preview_merchant_image = null;

  wyswigconfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: "Enter text here...",
    translate: "no",
    customClasses: [
      {
        name: "quote",
        class: "quote"
      },
      {
        name: "redText",
        class: "redText"
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1"
      }
    ]
  };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private merchantService: MerchantService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.merchantForm = this.formBuilder.group({
      merchant_name: ["", [Validators.required, Utils.noWhitespaceValidator]],
      merchant_image: ["", [Validators.required, Utils.noWhitespaceValidator]],
      merchant_status: ["A", [Validators.required, Utils.noWhitespaceValidator]]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.merchantForm.controls;
  }

  IconChange(files: FileList) {
    this.merchant_image = files[0];
    this.merchantForm.get("merchant_image").setValue(this.merchant_image.name);
    var reader = new FileReader();
    reader.readAsDataURL(this.merchant_image);
    reader.onload = _event => {
      this.preview_merchant_image = reader.result;
    };
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.merchantForm.invalid) {
      return;
    }

    this.loading = true;

    const formData = new FormData();
    formData.append(
      "merchant_name",
      this.merchantForm.get("merchant_name").value
    );
    formData.append(
      "merchant_photo",
      this.merchant_image,
      this.merchant_image.name
    );
    formData.append(
      "merchant_status",
      this.merchantForm.get("merchant_status").value
    );

    this.merchantService
      .insert(formData)
      .pipe(first())
      .subscribe(
        (data: any) => {
          if (data.status == "ok") {
            this.alertService.success(data.message, true);
            this.router.navigate(["/merchant"]);
          } else {
            this.alertService.error(data.message, true);
            this.loading = false;
          }
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }
}
