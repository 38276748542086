import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class PageService {
    constructor(public http: HttpClient) { }

    public getAll(filter: any = []) {
        return this.http.post<any>(`pages/listpage`, filter);
    }

    getById(id: number) {
        return this.http.get(`pages/view/` + id);
    }

    insert(page: any) {
        return this.http.post(`pages/add`, page);
    }

    update(page: any) {
        return this.http.post(`pages/update`, page);
    }

    delete(id: number) {
        return this.http.get(`pages/delete/` + id);
    }
}
