import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";

import { AlertService, EmailtemplateService } from "../../_services";
import { Utils } from "../../_helpers";

@Component({ templateUrl: "emailtemplate.view.html" })
export class EmailtemplateView implements OnInit {
  heading = "View Email Template";
  subheading = "";
  icon = "fa fa-envelope icon-gradient bg-happy-itmeo";
  emailtemplateForm: FormGroup;
  loading = false;
  submitted = false;
  template_id: number;
  preview_image = null;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private emailtemplateService: EmailtemplateService,
    private alertService: AlertService
  ) {
    this.template_id = this.route.snapshot.params["id"];
  }

  ngOnInit() {
    this.getBlock(this.template_id);
    this.emailtemplateForm = this.formBuilder.group({
      template_name: ["", [Validators.required, Utils.noWhitespaceValidator]],
      description: ["", Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.emailtemplateForm.controls;
  }

  getBlock(id) {
    this.emailtemplateService.getById(id).subscribe((data: any) => {
      var emailtemplate = data.result_set;
      this.emailtemplateForm.setValue({
        template_name: emailtemplate.template_name,
        description: emailtemplate.template_description
      });
    });
  }

  getControlLabel(type: string) {
    return this.emailtemplateForm.controls[type].value;
  }
}
