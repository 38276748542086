import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { AngularEditorConfig } from "@kolkov/angular-editor";

import {
  AlertService,
  MerchantService,
  CategoryService,
  DealService
} from "../../_services";
import { Utils } from "../../_helpers";

@Component({ templateUrl: "deal.edit.html" })
export class DealEdit implements OnInit {
  heading = "Edit Deal";
  subheading = "";
  icon = "fa fa-tags icon-gradient bg-happy-itmeo";
  dealForm: FormGroup;
  loading = false;
  submitted = false;
  categorys: any = [];
  merchants: any = [];
  deal_id: any;
  ExpminDate = new Date();
  ExpmaxDate = null;
  image = null;
  preview_image = null;

  wyswigconfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: "Enter text here...",
    translate: "no",
    customClasses: [
      {
        name: "quote",
        class: "quote"
      },
      {
        name: "redText",
        class: "redText"
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1"
      }
    ]
  };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    private merchantService: MerchantService,
    private dealService: DealService,
    private alertService: AlertService
  ) {
    this.deal_id = this.route.snapshot.params["id"];
  }

  ngOnInit() {
    this.getDeal(this.deal_id);
    this.dealForm = this.formBuilder.group({
      merchant_id: ["", Validators.required],
      category_id: ["", Validators.required],
      deal_name: ["", [Validators.required, Utils.noWhitespaceValidator]],
      hot_deals: ["", Validators.required],
      image: ["", [Validators.required, Utils.noWhitespaceValidator]],
      description: ["", [Validators.required, Utils.noWhitespaceValidator]],
      location: ["", Validators.required],
      coin: ["", [Validators.required, Utils.noWhitespaceValidator]],
      terms: ["", [Validators.required, Utils.noWhitespaceValidator]],
      start_date: ["", Validators.required],
      end_date: ["", Validators.required],
      status: ["", Validators.required]
    });
    this.loadAllCategorys();
    this.loadAllMerchants();
    this.ExpStrtDateChange();
    this.ExpEndDateChange();
  }

  private loadAllCategorys() {
    this.categoryService
      .getAll({ search_field: "status", search_value: "A" })
      .pipe(first())
      .subscribe((categorys: any) => {
        if (categorys.status == "ok") {
          this.categorys = categorys.result_set;
        }
      });
  }

  private loadAllMerchants() {
    this.merchantService
      .getAll({ search_field: "merchant_status", search_value: "A" })
      .pipe(first())
      .subscribe((merchants: any) => {
        if (merchants.status == "ok") {
          this.merchants = merchants.result_set;
        }
      });
  }

  ExpStrtDateChange() {
    this.dealForm.get("start_date").valueChanges.subscribe((Date: any) => {
      if (Date) {
        this.ExpminDate = Date;
      }
    });
  }

  ExpEndDateChange() {
    this.dealForm.get("end_date").valueChanges.subscribe((Date: any) => {
      if (Date) {
        this.ExpmaxDate = Date;
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.dealForm.controls;
  }
  IconChange(files: FileList) {
    this.image = files[0];
    this.dealForm.get("image").setValue(this.image.name);
    var reader = new FileReader();
    reader.readAsDataURL(this.image);
    reader.onload = _event => {
      this.preview_image = reader.result;
    };
  }

  getDeal(id) {
    this.dealService.getById(id).subscribe((data: any) => {
      var deal = data.result_set;
      var hotDeals = deal.hot_deals == "Y" ? true : false;
      this.dealForm.setValue({
        merchant_id: deal.merchant_id,
        category_id: deal.category_id,
        deal_name: deal.deal_name,
        hot_deals: hotDeals,
        image: deal.deal_image,
        description: deal.description,
        location: deal.location,
        coin: deal.coin,
        terms: deal.terms,
        start_date: new Date(deal.start_date),
        end_date: new Date(deal.end_date),
        status: deal.status
      });
      this.preview_image = deal.deal_image;
    });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.dealForm.invalid) {
      return;
    }

    this.loading = true;
    var hot_deals = this.dealForm.get("hot_deals").value == true ? "Y" : "N";
    const formData = new FormData();
    formData.append("deal_id", this.deal_id);
    formData.append("merchant_id", this.dealForm.get("merchant_id").value);
    formData.append("category_id", this.dealForm.get("category_id").value);
    formData.append("deal_name", this.dealForm.get("deal_name").value);
    formData.append("hot_deals", hot_deals);
    formData.append("description", this.dealForm.get("description").value);
    formData.append("location", this.dealForm.get("location").value);
    formData.append("coin", this.dealForm.get("coin").value);
    formData.append("terms", this.dealForm.get("terms").value);
    if (this.image != null) {
      formData.append("deal_image", this.image, this.image.name);
    }
    formData.append(
      "start_date",
      new Date(`${this.dealForm.get("start_date").value} UTC`).toISOString()
    );
    formData.append(
      "end_date",
      new Date(`${this.dealForm.get("end_date").value} UTC`).toISOString()
    );
    formData.append("status", this.dealForm.get("status").value);

    this.dealService
      .update(formData)
      .pipe(first())
      .subscribe(
        (data: any) => {
          if (data.status == "ok") {
            this.alertService.success(data.message, true);
            this.router.navigate(["/deal"]);
          } else {
            this.alertService.error(data.message, true);
            this.loading = false;
          }
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }
}
