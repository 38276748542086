import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { MatTableDataSource, MatSort, MatPaginator } from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";

// import { Merchant } from '../../_models';
import { AlertService, DealService } from "../../_services";

@Component({ templateUrl: "deal.list.html" })
export class DealList implements OnInit, OnDestroy {
  heading = "Deals";
  subheading = "";
  icon = "fa fa-tags icon-gradient bg-happy-itmeo";
  link = "/deal/add";
  redeemdeal = "/deal/redeem";
  exportbutton = "true";
  merchants: [];
  dataSource: MatTableDataSource<any[]>;
  search_key = "";
  pageSizeOptions = null;
  data:any = [];

  displayedColumns = ["s_no", "deal_name", "status", "actions"];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(",").map(str => +str);
  }

  constructor(
    private dealServices: DealService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.loadAllDeals();
  }

  ngOnDestroy() {}

  clearFilters() {
    this.dataSource.filter = "";
    this.search_key = "";
  }

  private loadAllDeals() {
    this.dealServices
      .getAll()
      .pipe(first())
      .subscribe((deals: any) => {
        if (deals.status == "ok") {
          this.dataSource = new MatTableDataSource(deals.result_set);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.data = deals.result_set;
        }
      });
  }

  deleteDeal(id: number) {
    this.alertService.delete().then(data => {
      if (data) {
        this.dealServices
          .delete(id)
          .pipe(first())
          .subscribe((data: any) => {
            if (data.status == "ok") {
              this.alertService.success(data.message, true);
              this.loadAllDeals();
            } else {
              this.alertService.error(data.message, true);
            }
          });
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onExported() {
    let fileName = 'DealsList.csv';
    let columnNames = ["Merchant", "category", "Deal Name", "Description", "Image", "Hot Deal", "Location", "Coin", "Terms", "Start Date", "End Date"];
    let header = columnNames.join(',');

    let csv = header;
    csv += '\r\n';

    this.data.map(c => {
      csv += [(c['merchant_name'])?'"' + c['merchant_name'].toString() + '"':'', (c['category_name'])?'"' + c['category_name'].toString() + '"':'', (c['deal_name'])?'"' + c['deal_name'].toString() + '"':'', (c['description'])?'"' + c['description'].toString() + '"':'', (c['deal_image'])?'"' + c['deal_image'].toString() + '"':'', (c["hot_deals"]=='Y')?'Yes':'No', (c['location'])?'"' + c['location'].toString() + '"':'', (c['coin'])?'"' + c['coin'].toString() + '"':'', (c['terms'])?'"' + c['terms'].toString() + '"':'', c["start_date"], c["end_date"], (c["status"]=="A")?'Active':'Inactive'].join(',');
      csv += '\r\n';
    })

    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    var link = document.createElement("a");
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
