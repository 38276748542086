import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { MatTableDataSource, MatSort, MatPaginator } from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";

import { AlertService, BestshowroomService } from "../../_services";

@Component({ templateUrl: "bestshowroom.list.html" })
export class BestshowroomList implements OnInit, OnDestroy {
  heading = "Best Showroom";
  subheading = "";
  icon = "fa fa-clone icon-gradient bg-happy-itmeo";
  bestshowrooms: [];
  dataSource: MatTableDataSource<any[]>;
  search_key = "";
  pageSizeOptions = null;
  ratingList = {
    "1": "Excellent",
    "2": "Good",
    "3": "Average",
    "4": "Satisfactory",
    "5": "Needs Improvement"
  };
  displayedColumns = [
    "s_no",
    "name",
    "email",
    "project_location",
    "visited_id",
    "rank",
    "created_on"
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(",").map(str => +str);
  }

  constructor(
    private bestshowroomServices: BestshowroomService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.loadAllBestshowrooms();
  }

  ngOnDestroy() {}

  clearFilters() {
    this.dataSource.filter = "";
    this.search_key = "";
  }

  private loadAllBestshowrooms() {
    this.bestshowroomServices
      .getAll()
      .pipe(first())
      .subscribe((bestshowrooms: any) => {
        if (bestshowrooms.status == "ok") {
          this.dataSource = new MatTableDataSource(bestshowrooms.result_set);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
