import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// import { Merchant } from '../_models';

@Injectable({ providedIn: 'root' })
export class MerchantService {
    constructor(public http: HttpClient) { }

    public getAll(filter: any = []) {
        return this.http.post<any>(`merchants/listmerchants`, filter);
    }

    getById(id: number) {
        return this.http.get(`merchants/view/` + id);
    }

    insert(merchant: any) {
        return this.http.post(`merchants/add`, merchant);
    }

    update(merchant: any) {
        return this.http.post(`merchants/update`, merchant);
    }

    delete(id: number) {
        return this.http.get(`merchants/delete/` + id);
    }
}
