import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { AngularEditorConfig } from "@kolkov/angular-editor";

import { AlertService, EmailtemplateService } from "../../_services";
import { Utils } from "../../_helpers";

@Component({ templateUrl: "emailtemplate.add.html" })
export class EmailtemplateAdd implements OnInit {
  heading = "Add Email Template";
  subheading = "";
  icon = "fa fa-envelope icon-gradient bg-happy-itmeo";
  emailtemplateForm: FormGroup;
  loading = false;
  submitted = false;

  wyswigconfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: "Enter text here...",
    translate: "no",
    customClasses: [
      {
        name: "quote",
        class: "quote"
      },
      {
        name: "redText",
        class: "redText"
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1"
      }
    ]
  };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private emailtemplateService: EmailtemplateService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.emailtemplateForm = this.formBuilder.group({
      template_name: ["", [Validators.required, Utils.noWhitespaceValidator]],
      description: ["", Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.emailtemplateForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.emailtemplateForm.invalid) {
      return;
    }

    this.loading = true;

    const formData = new FormData();
    formData.append(
      "template_name",
      this.emailtemplateForm.get("template_name").value
    );
    formData.append(
      "description",
      this.emailtemplateForm.get("description").value
    );

    this.emailtemplateService
      .insert(formData)
      .pipe(first())
      .subscribe(
        (data: any) => {
          if (data.status == "ok") {
            this.alertService.success(data.message, true);
            this.router.navigate(["/emailtemplate"]);
          } else {
            this.alertService.error(data.message, true);
            this.loading = false;
          }
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }
}
