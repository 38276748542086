import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { first } from "rxjs/operators";
import { MatTableDataSource, MatSort, MatPaginator } from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";

// import { Customer } from '../../_models';
import { AlertService, CustomerService } from "../../_services";

@Component({ templateUrl: "customer.listcoin.html" })
export class CustomerListCoin implements OnInit, OnDestroy {
  heading = "Customer Coins";
  subheading = "";
  icon = "fa fa-money icon-gradient bg-happy-itmeo";
  link = "/customer/addcoin";
  customers: [];
  dataSource: MatTableDataSource<any[]>;
  pageSizeOptions = null;
  search_key = "";
  customer_id: any;
  typeList = {
    "C": "Credit",
    "D": "Debit"
  };

  displayedColumns = ["s_no", "name", "email", "coins", "coin_type", "remarks"];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(",").map(str => +str);
  }

  constructor(
    private customerService: CustomerService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.customer_id = this.route.snapshot.params["id"];
  }

  ngOnInit() {
    this.loadAllCustomers();
  }

  ngOnDestroy() {}

  clearFilters() {
    this.dataSource.filter = "";
    this.search_key = "";
  }

  private loadAllCustomers() {
    let customer = {
      customer_id: this.customer_id != "all" ? this.customer_id : ""
    };
    this.customerService
      .getcoinAll(customer)
      .pipe(first())
      .subscribe((customers: any) => {
        if (customers.status == "ok") {
          this.dataSource = new MatTableDataSource(customers.result_set);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
