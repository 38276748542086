import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class Utils {
  public static noWhitespaceValidator(control: FormControl) {
  	if(control.value!='') {
    	const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { 'whitespace': true };
    } else {
    	return null;
    }
	}
}