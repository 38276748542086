import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { MustMatch } from '../../_helpers/must-match.validator';
import { AlertService, CustomerService } from '../../_services';
import { Utils } from '../../_helpers';

@Component({templateUrl: 'customer.view.html'})
export class CustomerView implements OnInit {
    heading = 'Edit Customer';
    subheading = '';
    icon = 'fa fa-users icon-gradient bg-happy-itmeo';
    customerForm: FormGroup;
    preview_customer_profile = null;
    customer_id = '';

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private customerService: CustomerService        
    ) {  
        this.customer_id = this.route.snapshot.params['id'];
    }

    ngOnInit() {
        this.getCustomer(this.customer_id);
        this.customerForm = this.formBuilder.group({
            customer_name: ['', [Validators.required, Utils.noWhitespaceValidator]],
            customer_email: ['', [Validators.required, Utils.noWhitespaceValidator, Validators.pattern('[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
            mobile_number: ['', Validators.required],
            employee_reference: ['', Validators.required],
            customer_status: ['', [Validators.required, Utils.noWhitespaceValidator]]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.customerForm.controls; }

    getCustomer(id) {
        this.customerService.getById(id).subscribe((data: any) => {
            var customer = data.result_set;
            this.customerForm.setValue({
              customer_name: customer.name,
              mobile_number: customer.mobile_number,
              customer_email: customer.email,
              customer_status: customer.status,
              employee_reference: customer.employee_reference
            });
            this.preview_customer_profile = customer.photo;
        });
    }

    getControlLabel(type: string){
     return this.customerForm.controls[type].value;
    }

}