import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";

// import { User } from '../../_models';
import { UserService, AuthenticationService } from "../../_services";

@Component({ templateUrl: "home.component.html" })
export class HomeComponent implements OnInit, OnDestroy {
  currentUser = null;
  currentUserSubscription: Subscription;
  users = null;
  ExpminDate = new Date();
  ExpmaxDate = null;
  start_date = null;
  end_date = null;

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(
      (user: any) => {
        this.currentUser = user;
      }
    );
  }

  ngOnInit() {
    this.loadAllUsers();
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    // this.currentUserSubscription.unsubscribe();
  }

  // StartDateChng(ev) {
  //   this.start_date = ev.target.value;
  //   console.log(this.start_date);
  // }

  // EndDateChng(ev) {
  //   this.end_date = ev.target.value;
  // }

  SearchData() {
    if (this.start_date && this.start_date != "") {
      let requestData = {
        params: {
          start_date: new Date(`${this.start_date} UTC`).toISOString(),
          end_date:
            this.end_date && this.end_date != ""
              ? new Date(`${this.end_date} UTC`).toISOString()
              : ""
        }
      };
      this.loadAllUsers(requestData);
    } else {
      this.loadAllUsers();
    }
  }
  private loadAllUsers(requestData = { params: {} }) {
    // const requestData = "start_date=&end_date";
    // console.log(requestData);
    this.userService
      .getDashboard(requestData)
      .pipe(first())
      .subscribe((users: any) => {
        if (users.result_set) {
          this.users = users.result_set;
        }
      });
  }
}
