import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

// import { Category } from '../_models';

@Injectable({ providedIn: "root" })
export class PointsService {
  constructor(public http: HttpClient) {}

  public getAll(filter: any = []) {
    return this.http.post<any>(`package/listpackage`, filter);
  }

  getById(id: number) {
    return this.http.get(`package/view/` + id);
  }

  insert(points: any) {
    return this.http.post(`package/add`, points);
  }

  update(points: any) {
    return this.http.post(`package/update`, points);
  }

  delete(id: number) {
    return this.http.get(`package/delete/` + id);
  }
}
