import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { AngularEditorConfig } from "@kolkov/angular-editor";

import { AlertService, CategoryService } from "../../_services";
import { Utils } from "../../_helpers";

@Component({ templateUrl: "category.edit.html" })
export class CategoryEdit implements OnInit {
  heading = "Edit Deal Category";
  subheading = "";
  icon = "fa fa-folder-o icon-gradient bg-happy-itmeo";
  categoryForm: FormGroup;
  loading = false;
  submitted = false;
  category_id: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    private alertService: AlertService
  ) {
    this.category_id = this.route.snapshot.params["id"];
  }

  ngOnInit() {
    this.getCategory(this.category_id);
    this.categoryForm = this.formBuilder.group({
      category_name: ["", [Validators.required, Utils.noWhitespaceValidator]],
      category_status: ["", [Validators.required, Utils.noWhitespaceValidator]]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.categoryForm.controls;
  }

  getCategory(id) {
    this.categoryService.getById(id).subscribe((data: any) => {
      var category = data.result_set;
      this.categoryForm.setValue({
        category_name: category.category_name,
        category_status: category.status
      });
    });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.categoryForm.invalid) {
      return;
    }

    this.loading = true;

    const formData = new FormData();
    formData.append("category_id", this.category_id);
    formData.append(
      "category_name",
      this.categoryForm.get("category_name").value
    );
    formData.append("status", this.categoryForm.get("category_status").value);

    this.categoryService
      .update(formData)
      .pipe(first())
      .subscribe(
        (data: any) => {
          if (data.status == "ok") {
            this.alertService.success(data.message, true);
            this.router.navigate(["/category"]);
          } else {
            this.alertService.error(data.message, true);
          }
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }
}
