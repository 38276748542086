import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

// import { Announcement } from '../_models';

@Injectable({ providedIn: "root" })
export class BestshowroomService {
  constructor(public http: HttpClient) {}

  public getAll(filter: any = []) {
    return this.http.post<any>(`best_showroom/listbest_showroom`, filter);
  }
}
