import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { MatTableDataSource, MatSort, MatPaginator } from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";

// import { Category } from '../../_models';
import { AlertService, CategoryService } from "../../_services";

@Component({ templateUrl: "category.list.html" })
export class CategoryList implements OnInit, OnDestroy {
  heading = "Deal Category";
  subheading = "";
  icon = "fa fa-folder-o icon-gradient bg-happy-itmeo";
  link = "/category/add";
  categorys: [];
  dataSource: MatTableDataSource<any[]>;
  search_key = "";
  pageSizeOptions = null;
  displayedColumns = ["s_no", "category_name", "status", "actions"];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(",").map(str => +str);
  }

  constructor(
    private categoryServices: CategoryService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.loadAllCategorys();
  }

  ngOnDestroy() {}

  clearFilters() {
    this.dataSource.filter = "";
    this.search_key = "";
  }

  private loadAllCategorys() {
    this.categoryServices
      .getAll()
      .pipe(first())
      .subscribe((categorys: any) => {
        if (categorys.status == "ok") {
          this.dataSource = new MatTableDataSource(categorys.result_set);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      });
  }

  deleteCategory(id: number) {
    this.alertService.delete().then(data => {
      if (data) {
        this.categoryServices
          .delete(id)
          .pipe(first())
          .subscribe((data: any) => {
            if (data.status == "ok") {
              this.alertService.success(data.message, true);
              this.loadAllCategorys();
            } else {
              this.alertService.error(data.message, true);
            }
          });
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
