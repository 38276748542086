import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { AlertService, CustomerService } from "../../_services";
import { Utils } from "../../_helpers";

@Component({ templateUrl: "customer.addcoin.html" })
export class CustomerAddCoin implements OnInit {
  heading = "Credit / Debit Coins";
  subheading = "";
  icon = "fa fa-money icon-gradient bg-happy-itmeo";
  customerForm: FormGroup;
  loading = false;
  submitted = false;
  customers: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private customerService: CustomerService
  ) {}

  ngOnInit() {
    this.customerForm = this.formBuilder.group({
      user_id: ["", [Validators.required, Utils.noWhitespaceValidator]],
      coin_type: ["", [Validators.required, Utils.noWhitespaceValidator]],
      coins: ["", [Validators.required]],
      remarks: ["", [Validators.required]]
    });
    this.loadAllCustomer();
  }

  private loadAllCustomer() {
    this.customerService
      .getAll({ search_field: "status", search_value: "A" })
      .pipe(first())
      .subscribe((customers: any) => {
        if (customers.status == "ok") {
          this.customers = customers.result_set;
        }
      });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.customerForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.customerForm.invalid) {
      return;
    }

    this.loading = true;

    const formData = new FormData();
    formData.append("customer_id", this.customerForm.get("user_id").value);
    formData.append("coin_type", this.customerForm.get("coin_type").value);
    formData.append("coin", this.customerForm.get("coins").value);
    formData.append("remarks", this.customerForm.get("remarks").value);

    this.customerService
      .insertcoin(formData)
      .pipe(first())
      .subscribe(
        (data: any) => {
          if (data.status == "ok") {
            this.alertService.success(data.message, true);
            this.router.navigate(["/customer/coinlist/all"]);
          } else {
            this.alertService.error(data.message, true);
            this.loading = false;
          }
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }
}
