import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { first } from "rxjs/operators";

import { AlertService, AuthenticationService } from "../../_services";
import { Utils } from "../../_helpers";

@Component({
  selector: "app-login-boxed",
  templateUrl: "./login.component.html",
  styles: []
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  username = new FormControl("");
  password = new FormControl("");
  returnUrl: string;
  submitted = false;
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) {
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(["/dashboard"]);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ["", [Validators.required, Utils.noWhitespaceValidator]],
      password: ["", [Validators.required, Utils.noWhitespaceValidator]]
    });

    this.returnUrl =
      this.route.snapshot.queryParams["returnUrl"] || "/dashboard";
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    const formData = new FormData();
    formData.append("username", this.loginForm.get("username").value);
    formData.append("password", this.loginForm.get("password").value);

    this.authenticationService
      .login(formData)
      .pipe(first())
      .subscribe(
        (data: any) => {
          if (data.status == "ok") {
            this.alertService.success(data.message, true);
            this.router.navigate([this.returnUrl]);
          } else {
            this.alertService.error(data.message, true);
            this.loading = false;
          }
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }
}
