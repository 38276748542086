import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";

import { AlertService, DealService } from "../../_services";
import { Utils } from "../../_helpers";

@Component({ templateUrl: "deal.view.html" })
export class DealView implements OnInit {
  heading = "View Deal";
  subheading = "";
  icon = "fa fa-tags icon-gradient bg-happy-itmeo";
  dealForm: FormGroup;
  loading = false;
  submitted = false;
  deal_id: number;
  image = null;
  preview_image = null;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private dealService: DealService,
    private alertService: AlertService
  ) {
    this.deal_id = this.route.snapshot.params["id"];
  }

  ngOnInit() {
    this.getDeal(this.deal_id);
    this.dealForm = this.formBuilder.group({
      merchant_name: ["", Validators.required],
      category_name: ["", Validators.required],
      deal_name: ["", [Validators.required, Utils.noWhitespaceValidator]],
      hot_deals: ["", Validators.required],
      description: ["", [Validators.required, Utils.noWhitespaceValidator]],
      location: ["", Validators.required],
      coin: ["", [Validators.required, Utils.noWhitespaceValidator]],
      terms: ["", [Validators.required, Utils.noWhitespaceValidator]],
      start_date: ["", Validators.required],
      end_date: ["", Validators.required],
      status: ["", Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.dealForm.controls;
  }

  getDeal(id) {
    this.dealService.getById(id).subscribe((data: any) => {
      var deal = data.result_set;
      this.dealForm.setValue({
        merchant_name: deal.merchant_name,
        category_name: deal.category_name,
        deal_name: deal.deal_name,
        hot_deals: deal.hot_deals,
        description: deal.description,
        location: deal.location,
        coin: deal.coin,
        terms: deal.terms,
        start_date: deal.start_date,
        end_date: deal.end_date,
        status: deal.status
      });
      this.preview_image = deal.deal_image;
    });
  }

  getControlLabel(type: string) {
    return this.dealForm.controls[type].value;
  }
}
