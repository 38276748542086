import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { AngularEditorConfig } from "@kolkov/angular-editor";

import { AlertService, ProjectService } from "../../_services";
import { Utils } from "../../_helpers";

@Component({ templateUrl: "project.add.html" })
export class ProjectAdd implements OnInit {
  heading = "Add Project";
  subheading = "";
  icon = "fa fa-tasks icon-gradient bg-happy-itmeo";
  projectForm: FormGroup;
  loading = false;
  submitted = false;
  image = null;
  preview_image = [];
  imageUplaod = false;

  wyswigconfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: "Enter text here...",
    translate: "no",
    customClasses: [
      {
        name: "quote",
        class: "quote"
      },
      {
        name: "redText",
        class: "redText"
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1"
      }
    ]
  };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private projectService: ProjectService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.projectForm = this.formBuilder.group({
      project_location: [
        "",
        [Validators.required, Utils.noWhitespaceValidator]
      ],
      project_type: ["", [Validators.required, Utils.noWhitespaceValidator]],
      image: ["", [Validators.required, Utils.noWhitespaceValidator]],
      status: ["A", [Validators.required, Utils.noWhitespaceValidator]]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.projectForm.controls;
  }

  IconChange(files: any) {
    this.image = files;
    var currentImageLength = files.length;
    var existImageLength = this.preview_image.length;
    var totalImageLength = parseInt(currentImageLength) + existImageLength;
    if (totalImageLength > 15) {
      let inputFields = document.getElementById("image") as HTMLInputElement;
      inputFields.value = "";
      this.alertService.error("Should allow maximum 15 images only.");
    } else {
      this.imageUplaod = true;
      const formData = new FormData();
      for (let file of files) {
        formData.append("image[]", file);
      }
      this.projectService
        .uploadImage(formData)
        .pipe(first())
        .subscribe(
          (data: any) => {
            if (data.status == "ok") {
              this.imageUplaod = false;
              let preview_image = this.preview_image;
              if (data.images !== "") {
                for (let image of data.images) {
                  preview_image.push({ name: image.name, url: image.url });
                }
                this.preview_image = preview_image;
                this.projectForm.patchValue({
                  image: this.preview_image.join(",")
                });
              }
              let inputFields1 = document.getElementById(
                "image"
              ) as HTMLInputElement;
              inputFields1.value = "";
            } else {
              this.alertService.error(data.message, true);
              //this.loading = false;
            }
          },
          error => {
            this.alertService.error(error);
            this.loading = false;
          }
        );
    }
  }
  RemoveImage(id) {
    this.alertService.delete().then(data => {
      if (data) {
        const formData = new FormData();
        let preview_image = this.preview_image;
        var removeImaage = preview_image[id].name;
        formData.append("image", removeImaage);
        this.projectService
          .removeImage(formData)
          .pipe(first())
          .subscribe(
            (data: any) => {
              if (data.status == "ok") {
                preview_image.splice(id, 1);
                this.preview_image = preview_image;
                this.projectForm.patchValue({
                  image: this.preview_image.join(",")
                });
              } else {
                this.alertService.error(data.message, true);
                //this.loading = false;
              }
            },
            error => {
              this.alertService.error(error);
              this.loading = false;
            }
          );
      }
    });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.projectForm.invalid) {
      return;
    }

    this.loading = true;

    const formData = new FormData();
    formData.append(
      "project_location",
      this.projectForm.get("project_location").value
    );
    formData.append("project_type", this.projectForm.get("project_type").value);

    var preview_image = this.preview_image;
    var images = [];
    if (preview_image.length > 0) {
      for (let image of preview_image) {
        images.push(image.name);
      }
      formData.append("image", images.join(","));
    }
    formData.append("status", this.projectForm.get("status").value);

    this.projectService
      .insert(formData)
      .pipe(first())
      .subscribe(
        (data: any) => {
          if (data.status == "ok") {
            this.alertService.success(data.message, true);
            this.router.navigate(["/project"]);
          } else {
            this.alertService.error(data.message, true);
            this.loading = false;
          }
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }
}
