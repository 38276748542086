import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";

import { AlertService, AnnouncementService } from "../../_services";
import { Utils } from "../../_helpers";

@Component({ templateUrl: "announcement.view.html" })
export class AnnouncementView implements OnInit {
  heading = "View Announcement";
  subheading = "";
  icon = "fa fa-bullhorn icon-gradient bg-happy-itmeo";
  announcementForm: FormGroup;
  loading = false;
  submitted = false;
  announcement_id: number;
  preview_image = null;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private announcementService: AnnouncementService,
    private alertService: AlertService
  ) {
    this.announcement_id = this.route.snapshot.params["id"];
  }

  ngOnInit() {
    this.getAnnouncement(this.announcement_id);
    this.announcementForm = this.formBuilder.group({
      title: ["", [Validators.required, Utils.noWhitespaceValidator]],
      description: ["", [Validators.required, Utils.noWhitespaceValidator]],
      status: ["", [Validators.required, Utils.noWhitespaceValidator]]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.announcementForm.controls;
  }

  getAnnouncement(id) {
    this.announcementService.getById(id).subscribe((data: any) => {
      var announcement = data.result_set;
      this.announcementForm.setValue({
        title: announcement.title,
        description: announcement.description,
        status: announcement.status
      });
      this.preview_image = announcement.image;
    });
  }

  getControlLabel(type: string) {
    return this.announcementForm.controls[type].value;
  }
}
