import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: "root" })
export class ProjectService {
  constructor(public http: HttpClient) {}

  public getAll(filter: any = []) {
    return this.http.post<any>(`projects/listprojects`, filter);
  }

  getById(id: number) {
    return this.http.get(`projects/view/` + id);
  }

  insert(projects: any) {
    return this.http.post(`projects/add`, projects);
  }

  update(projects: any) {
    return this.http.post(`projects/update`, projects);
  }

  delete(id: number) {
    return this.http.get(`projects/delete/` + id);
  }

  uploadImage(projects: any) {
    return this.http.post(`projects/add_image`, projects);
  }
  removeImage(projects: any) {
    return this.http.post(`projects/delete_image`, projects);
  }
}
