import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class BlockService {
    constructor(public http: HttpClient) { }

    public getAll(filter: any = []) {
        return this.http.post<any>(`staticblocks/liststaticblocks`, filter);
    }

    getById(id: number) {
        return this.http.get(`staticblocks/view/` + id);
    }

    insert(block: any) {
        return this.http.post(`staticblocks/add`, block);
    }

    update(block: any) {
        return this.http.post(`staticblocks/update`, block);
    }

    delete(id: number) {
        return this.http.get(`staticblocks/delete/` + id);
    }
}
