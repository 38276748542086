import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { AngularEditorConfig } from "@kolkov/angular-editor";

import { AlertService, EmailtemplateService } from "../../_services";
import { Utils } from "../../_helpers";

@Component({ templateUrl: "emailtemplate.edit.html" })
export class EmailtemplateEdit implements OnInit {
  heading = "Edit Email Template";
  subheading = "";
  icon = "fa fa-envelope icon-gradient bg-happy-itmeo";
  emailtemplateForm: FormGroup;
  loading = false;
  submitted = false;
  template_id: any;
  image = null;
  preview_image = null;

  wyswigconfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: "Enter text here...",
    translate: "no",
    customClasses: [
      {
        name: "quote",
        class: "quote"
      },
      {
        name: "redText",
        class: "redText"
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1"
      }
    ]
  };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private emailtemplateService: EmailtemplateService,
    private alertService: AlertService
  ) {
    this.template_id = this.route.snapshot.params["id"];
  }

  ngOnInit() {
    this.getBlock(this.template_id);
    this.emailtemplateForm = this.formBuilder.group({
      template_name: ["", [Validators.required, Utils.noWhitespaceValidator]],
      description: ["", Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.emailtemplateForm.controls;
  }

  getBlock(id) {
    this.emailtemplateService.getById(id).subscribe((data: any) => {
      var emailtemplate = data.result_set;
      this.emailtemplateForm.setValue({
        template_name: emailtemplate.template_name,
        description: emailtemplate.template_description
      });
    });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.emailtemplateForm.invalid) {
      return;
    }

    this.loading = true;

    const formData = new FormData();
    formData.append("template_id", this.template_id);
    formData.append(
      "template_name",
      this.emailtemplateForm.get("template_name").value
    );
    formData.append(
      "description",
      this.emailtemplateForm.get("description").value
    );

    if (this.image != null) {
      formData.append("image", this.image, this.image.name);
    }

    this.emailtemplateService
      .update(formData)
      .pipe(first())
      .subscribe(
        (data: any) => {
          if (data.status == "ok") {
            this.alertService.success(data.message, true);
            this.router.navigate(["/emailtemplate"]);
          } else {
            this.alertService.error(data.message, true);
            this.loading = false;
          }
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }
}
