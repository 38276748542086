import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({ providedIn: 'root' })
export class AlertService {
    private subject = new Subject<any>();
    private keepAfterNavigationChange = false;

    constructor(private router: Router) {
        // clear alert message on route change
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
                    this.subject.next();
                }
            }
        });
        this.subject.next();
    }

    success(message: string, keepAfterNavigationChange = false) {
        Swal('Success!',message,'success');
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        /*this.subject.next({ type: 'success', text: message });*/        
    }

    successLogout(message: string, keepAfterNavigationChange = false) {
      Swal({
          title: "Success!",
          text: message,
          type: "success"
      }).then(function() {
          document.location.href = '/login'
      });       
    }

    error(message: string, keepAfterNavigationChange = false) {
        Swal({
          title: 'Error',
          type: 'error',
          html: message,
          showCloseButton: true
        })
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        // this.subject.next({ type: 'error', text: message });
    }

    delete() {

        var res = new Promise((resolve, reject) => {
            Swal({
              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
              if (result.value) {
                resolve(true);
                this.keepAfterNavigationChange = false;
              } else {
                resolve(false);
                this.keepAfterNavigationChange = true;
              }
            })
        });

        return res;
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    showCode(message: string, keepAfterNavigationChange = false) {
      Swal(message,'','success');
      this.keepAfterNavigationChange = keepAfterNavigationChange;
    }
}