import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { AngularEditorConfig } from "@kolkov/angular-editor";

import { AlertService, AnnouncementService } from "../../_services";
import { Utils } from "../../_helpers";

@Component({ templateUrl: "announcement.add.html" })
export class AnnouncementAdd implements OnInit {
  heading = "Add Announcement";
  subheading = "";
  icon = "fa fa-bullhorn icon-gradient bg-happy-itmeo";
  announcementForm: FormGroup;
  loading = false;
  submitted = false;
  image = null;
  preview_image = null;
  ExpminDate = new Date();
  ExpmaxDate = null;
  MinDate = new Date();

  wyswigconfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: "Enter text here...",
    translate: "no",
    customClasses: [
      {
        name: "quote",
        class: "quote"
      },
      {
        name: "redText",
        class: "redText"
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1"
      }
    ]
  };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private announcementService: AnnouncementService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.announcementForm = this.formBuilder.group({
      title: ["", [Validators.required, Utils.noWhitespaceValidator]],
      description: ["", [Validators.required, Utils.noWhitespaceValidator]],
      image: ["", [Validators.required, Utils.noWhitespaceValidator]],
      start_date: ["", Validators.required],
      end_date: ["", Validators.required],
      status: ["A", [Validators.required, Utils.noWhitespaceValidator]]
    });
    this.ExpStrtDateChange();
    this.ExpEndDateChange();
  }

  ExpStrtDateChange() {
    this.announcementForm
      .get("start_date")
      .valueChanges.subscribe((Date: any) => {
        if (Date) {
          this.ExpminDate = Date;
        }
      });
  }

  ExpEndDateChange() {
    this.announcementForm
      .get("end_date")
      .valueChanges.subscribe((Date: any) => {
        if (Date) {
          this.ExpmaxDate = Date;
        }
      });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.announcementForm.controls;
  }

  IconChange(files: FileList) {
    this.image = files[0];
    this.announcementForm.get("image").setValue(this.image.name);
    var reader = new FileReader();
    reader.readAsDataURL(this.image);
    reader.onload = _event => {
      this.preview_image = reader.result;
    };
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.announcementForm.invalid) {
      return;
    }

    this.loading = true;

    const formData = new FormData();
    formData.append("title", this.announcementForm.get("title").value);
    formData.append("image", this.image, this.image.name);
    formData.append(
      "description",
      this.announcementForm.get("description").value
    );
    formData.append(
      "start_date",
      new Date(
        `${this.announcementForm.get("start_date").value} UTC`
      ).toISOString()
    );
    formData.append(
      "end_date",
      new Date(
        `${this.announcementForm.get("end_date").value} UTC`
      ).toISOString()
    );
    formData.append("status", this.announcementForm.get("status").value);

    this.announcementService
      .insert(formData)
      .pipe(first())
      .subscribe(
        (data: any) => {
          if (data.status == "ok") {
            this.alertService.success(data.message, true);
            this.router.navigate(["/announcement"]);
          } else {
            this.alertService.error(data.message, true);
            this.loading = false;
          }
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }
}
